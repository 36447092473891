import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';

import { ReactComponent as SvgSearch } from 'shared/assets/icons/icon-search.svg';
import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgAddApp } from 'shared/assets/icons/icon-addapp.svg';
import { ReactComponent as SvgBookmark } from 'shared/assets/icons/icon-bookmark.svg';

export const Wrap = styled(motion.div)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const SearchWrap = styled.section`
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 20px 0;
`;
export const IconSearch = styled(SvgSearch)`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: var(--object-secondary);
`;
export const SearchForm = styled.form`
  flex: 1;
`;
export const SearchInput = styled.input`
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  line-height: 20px;
  font-size: var(--font-sm);
`;
export const IconArrow = styled(SvgArrow)`
  width: 20px;
  height: 20px;
  stroke: var(--object-secondary);
`;
const Row = styled.section`
  width: 100%;
  margin: 0 0 20px 0;
`;
export const Header = styled.header<{ opened?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 10px 0;
  cursor: pointer;

  ${(prop) =>
    prop.opened &&
    css`
      ${IconArrow} {
        transform: rotate(180deg);
      }
    `}
`;
export const HeaderTitle = styled.h3`
  font-size: var(--font-sm);
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 10px 0 0;
`;
export const Bookmarks = styled(Row)``;
export const AllApps = styled(Row)`
  margin: 0;
`;
export const AppWrap = styled.div`
  display: flex;
  aling-items: stretch;
  flex-wrap: wrap;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 20px;
`;
export const IconBookmark = styled(SvgBookmark)`
  width: 20px;
  height: 20px;
  transition: all 0.4;
  fill: rgba(255, 255, 255, 0);
  stroke: rgba(255, 255, 255, 0);
`;
export const AppFeatured = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0;
  transition: all 0.4;
  cursor: pointer;

  ${IconBookmark} {
    stroke: var(--object-secondary);
  }

  &:hover {
    ${IconBookmark} {
      stroke: var(--object-primary);
    }
  }
`;
const AppItemCSS = css`
  height: 280px;
`;
export const AppItem = styled.div<{ featured?: boolean }>`
  ${AppItemCSS}
  padding: 20px;
  border-radius: 5px;
  background-color: var(--base);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;

  &:hover {
    ${AppFeatured} {
      opacity: 1;
    }
  }

  ${(prop) =>
    prop.featured &&
    css`
      ${IconBookmark} {
        stroke: var(--object-secondary);
        fill: var(--object-secondary);
      }
      ${AppFeatured} {
        &:hover {
          ${IconBookmark} {
            stroke: var(--object-primary);
            fill: var(--object-primary);
          }
        }
      }
    `}
`;
export const AppItemEmpty = styled.div`
  font-size: var(--font-xxl);
  color: var(--object-secondary);
  background: var(--highlight);
  padding: 60px 20px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
`;
export const AddAppItem = styled(AppItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
export const AppName = styled.h3`
  margin: 20px 0 0 0;
  font-size: var(--font-xxl);
  line-height: 21px;
  font-weight: 700;
`;
export const AppDescription = styled.div`
  margin: 20px 0 0 0;
  font-size: var(--font-sm);
`;
export const IconAddApp = styled(SvgAddApp)`
  width: 40px;
  height: 40px;
  margin: 0 0 20px 0;
`;
export const IconTextBookmark = styled(SvgBookmark)`
  width: 20px;
  height: 20px;
  stroke: currentColor;
  display: inline-block;
`;
export const AppsNotice = styled.section`
  padding: 0;
  font-size: var(--font-xxs);
  line-height: 12px;
  color: var(--object-secondary);
  margin-bottom: 20px;
`;
export const Buttons = styled.div`
  display: flex;
`;
export const FormFooter = styled.div`
  display: flex;
  position: relative;
  padding: 20px 0 0 0;
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border);
`;

export const AppsTags = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-top: 16px;
`;

export const Tag = styled.span`
  background-color: var(--border);
  height: 24px;
  padding: 0 12px;
  font-size: var(--font-sm);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
`;
