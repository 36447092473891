import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Link, Button, Element } from 'react-scroll';

import { ReactComponent as SvgDocument } from 'shared/assets/icons/icon-document.svg';
import { ReactComponent as SvgDevice } from 'shared/assets/icons/icon-device.svg';

export const Wrap = styled(motion.div)`
  min-height: 100%;
  width: 100%;
  background: var(--base);
  position: relative;
  border: 1px solid var(--border);
  border-top: none;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  min-width: 455px;
`;
export const Header = styled.header`
  padding: 20px;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--base);
  z-index: 99;
  margin: 0;
`;
export const AnchorList = styled.nav`
  display: flex;
`;
export const Anchor = styled(Link)`
  display: inline-block;
  background: var(--background);
  color: var(--object-primary);
  font-size: var(--font-xxxs);
  font-weight: 400;
  line-height: 10px;
  padding: 10px 15px;
  border-radius: 15px;
  margin: 0 5px 0 0;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;

  span {
    font-weight: 700;
    margin: 0 3px 0 0;
  }

  &.active {
    background: var(--accent-primary);
    color: var(--dark-bg);
  }
`;

export const Section = styled(Element)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 20px 20px;

  &.all {
    min-height: 1px;
    padding-bottom: 0;
  }
`;
export const ArticlesGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
`;
export const ArticlesItem = styled.div`
  background: var(--highlight);
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 20px;
  transition: all 0.2s;
  position: relative;

  &:hover {
    background: var(--base);
  }
`;
export const ArticleHeader = styled.header`
  display: flex;
`;
export const ArticleTitle = styled.h4`
  font-size: var(--font-md);
  font-weight: 700;
  margin: 0 0 10px 0;
`;
export const IconDoc = styled(SvgDocument)`
  width: 20px;
  height: 20px;
  stroke: var(--accent-secondary);
  margin: 0 10px 0 0;
`;
export const SectionTitle = styled.h3`
  font-size: var(--font-xxl);
  font-weight: 700;
  margin: 0 0 20px 0;
  width: 100%;
  display: flex;
  align-items: center;

  ${IconDoc} {
    margin: 0 10px 0 0;
  }
`;
export const ArticleDescription = styled.div`
  font-size: var(--font-sm);
  font-weight: 400;
  width: 100%;
`;
export const ArticleLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  text-decoration: none;
  z-index: 2;
`;

export const HeaderIconCss = css`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: var(--accent-primary);

  path {
    stroke: var(--accent-primary);
  }
`;

export const IconDevice = styled(SvgDevice)`
  ${HeaderIconCss}
`;
