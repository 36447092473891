import { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from 'use-onclickoutside';
import AppContext from 'shared/contexts/AppContext';
import Circle from 'shared/components/Сircle';
import AddresBar from 'shared/components/AddresBar';
import Select from 'shared/components/Select';
import SelectAsset from 'shared/components/SelectAsset';
import Dropdown from 'shared/components/Dropdown';

import * as M from '../styled/main';
import * as Modal from 'shared/components/Modal';
import * as Form from 'shared/components/Form';
import * as F from '../styled/form';
import * as Checkbox from 'shared/components/Checkbox';

import { getCoinsInfoById } from 'shared/constants/coins';

import { Formik } from 'formik';
import { SafeStoreInstance } from '../../mobx/SafeStore';
import { calculateGasPrice } from 'features/Multisig/mobx/logic/ethTransactions';
import { getCryptoValue } from 'shared/helpers/currencies';
import { MultisigTransactionsStoreInstance } from '../../mobx/MultisigTransactionsStore';
import { getNonce } from 'features/Multisig/helpers';
import { LATEST_SAFE_VERSION } from 'features/Multisig/constants';
import { SettingsStoreInstance } from 'services';

const TransactionAmountWrap = styled.div`
  display: flex;
  margin: 20px 0 20px 0;
  font-size: var(--font-xxxxl);
  line-height: 30px;
`;
const TransactionAmount = styled.span`
  font-family: var(--font-monospaced);
  margin: 0 0 0 10px;
`;
const TransactionAsset = styled.span`
  text-transform: uppercase;
  margin: 0 0 0 5px;
`;
const From = styled.div`
  padding: 20px 0;
`;

type RecipientType = {
  name: string;
  address: string;
};

const ADVANCED_INIT: {
  nonce: string;
} = {
  nonce: '1',
};
function SendTransaction() {
  const { appSettings } = SettingsStoreInstance;
  const [amount, setAmount] = useState(0.01);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [recipient, setRecipient] = useState('');
  const { multisigTransactionAsset, setStep, step, openModal } =
    useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(step);
  const [advancedButtonsShown, showAdvancedButtons] = useState(false);
  const [recommendedNonce, setRecommendedNonce] = useState<string>();
  const [nonce, setNonce] = useState<string>();
  const [inputNonce, setInputNonce] = useState<string>();

  const { coins, getCoins, selectedSafe, isHardwareWallet, wallet } =
    SafeStoreInstance;
  const { createTransaction, getTransactionQueue, getTxParameters } =
    MultisigTransactionsStoreInstance;

  useEffect(() => {
    getCoins();
    // @ts-ignore
    getNonce(selectedSafe?.address?.value, LATEST_SAFE_VERSION).then(
      (nonce) => {
        setNonce(nonce);
        setInputNonce(nonce);
        setRecommendedNonce(nonce);
      },
    );
  }, []);

  const handleStep = (value: number) => {
    setStep(value);
    setCurrentStep(value);
  };

  const changeNonce = (e: any) => {
    setInputNonce(e.target.value);
    showAdvancedButtons(true);
  };

  const confirmNonce = (cancel = false) => {
    showAdvancedButtons(false);
    if (cancel) {
      setInputNonce(nonce);
    } else {
      setNonce(inputNonce);
    }
  };

  const onSubmit = () => {
    try {
      setWasSubmitted(true);
      MultisigTransactionsStoreInstance.confirm = false;
      MultisigTransactionsStoreInstance.execute = false;
      MultisigTransactionsStoreInstance.isFinalization = null;
      createTransaction({
        nonce: String(nonce),
        from: selectedSafe?.address.value || '',
        to: recipient,
        amount,
        onFinish: () => {
          getTransactionQueue();
          openModal(null);
        },
      });
    } catch (error) {
      setWasSubmitted(false);
    }
  };

  return (
    <Formik
      initialValues={{
        data: '',
        recipient,
        amount,
        execute: true,
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form.MainForm>
          <F.RightHeaderTitle>{`Step ${step} of 2`}</F.RightHeaderTitle>
          {step === 2 ? (
            <TransactionAmountWrap>
              {getCoinsInfoById(
                multisigTransactionAsset.asset,
                appSettings.currencies_icons,
              )?.logo_url ? (
                <Circle
                  size={30}
                  image={
                    getCoinsInfoById(
                      multisigTransactionAsset.asset,
                      appSettings.currencies_icons,
                    )?.logo_url
                  }
                />
              ) : null}
              <TransactionAmount>{amount}</TransactionAmount>
              <TransactionAsset>
                {multisigTransactionAsset.asset}
              </TransactionAsset>
            </TransactionAmountWrap>
          ) : null}
          <Modal.SubHeader>Sending from</Modal.SubHeader>
          {selectedSafe && (
            <From>
              <AddresBar
                name={selectedSafe?.address.name}
                address={selectedSafe?.address.value}
                tools={true}
              />
            </From>
          )}
          {step === 1 ? (
            <SelectAsset
              default={multisigTransactionAsset}
              data={coins?.items.map(
                ({ balance, tokenInfo: { name, symbol } }) => ({
                  name,
                  value: getCryptoValue(Number(balance)),
                  asset: symbol,
                }),
              )}
            />
          ) : null}
          <F.Hr>
            <F.IconTransfer />
          </F.Hr>
          {step === 1 ? (
            <>
              <Form.Fieldset
                error={(errors.recipient && touched.recipient) || false}
              >
                <Form.LabelWrap>
                  <Form.Label>Recipient</Form.Label>
                </Form.LabelWrap>
                <Form.InputWrap>
                  <Form.Input
                    type="string"
                    name="recipient"
                    autoComplete="off"
                    id="recipient"
                    value={recipient}
                    onChange={(e: any) => setRecipient(e.target.value)}
                  />
                </Form.InputWrap>
              </Form.Fieldset>
              <Form.Fieldset error={(errors.amount && touched.amount) || false}>
                <Form.LabelWrap>
                  <Form.Label>Amount</Form.Label>
                  <Form.Usemax
                    type="button"
                    onClick={() => setAmount(multisigTransactionAsset.value)}
                  >
                    Send max
                  </Form.Usemax>
                </Form.LabelWrap>
                <Form.InputWrap>
                  <Form.Input
                    type="number"
                    name="amount"
                    autoComplete="off"
                    id="amount"
                    min={0}
                    value={amount}
                    step="0.01"
                    onChange={(e: any) => setAmount(e.target.value)}
                  />
                  {multisigTransactionAsset ? (
                    <Form.FieldAlt>
                      {multisigTransactionAsset.asset}
                    </Form.FieldAlt>
                  ) : null}
                  {amount > multisigTransactionAsset.value && (
                    <Form.InputError>Insufficient</Form.InputError>
                  )}
                </Form.InputWrap>
              </Form.Fieldset>
            </>
          ) : null}
          {step === 2 ? (
            <>
              <Modal.SubHeader>Recipient</Modal.SubHeader>
              <From>
                <AddresBar name={''} address={recipient} tools />
              </From>
              <Dropdown>
                <Form.Fieldset
                  error={(errors.amount && touched.amount) || false}
                >
                  <Form.LabelWrap>
                    <Form.Label>Wallet nonce</Form.Label>
                  </Form.LabelWrap>
                  <Form.Input
                    type="number"
                    name="nonce"
                    id="nonce"
                    value={inputNonce}
                    onChange={(e: any) => changeNonce(e)}
                  />
                </Form.Fieldset>
                {advancedButtonsShown ? (
                  <Form.Buttons>
                    <M.CancelButton
                      type="button"
                      onClick={() => confirmNonce(true)}
                    >
                      Cancel
                    </M.CancelButton>
                    <M.Button type="button" onClick={() => confirmNonce()}>
                      Confirm
                    </M.Button>
                  </Form.Buttons>
                ) : null}
                {nonce && recommendedNonce && nonce !== recommendedNonce && (
                  <div>
                    {nonce > recommendedNonce
                      ? `${
                          Number(nonce) - Number(recommendedNonce)
                        } transactions will need to be created and executed before this transaction, are you sure you want to do this?`
                      : `Nonce ${nonce} is below the latest transaction's nonce in your queue. Please verify the submitted nonce. The next recommended nonce is ${recommendedNonce}.`}
                  </div>
                )}
              </Dropdown>
            </>
          ) : null}
          <Form.FormFooter>
            <Form.FooterText>
              You are about to create a transaction and will have to confirm it
              with your connected wallet.
            </Form.FooterText>
            {step === 1 ? (
              <Form.Buttons>
                <M.CancelButton type="button" onClick={() => openModal(null)}>
                  Cancel
                </M.CancelButton>
                <M.SignButton
                  type="button"
                  onClick={() => handleStep(2)}
                  disabled={false}
                >
                  Review
                </M.SignButton>
              </Form.Buttons>
            ) : (
              <Form.Buttons>
                <M.CancelButton type="button" onClick={() => setStep(1)}>
                  Back
                </M.CancelButton>
                <M.SignButton
                  disabled={wasSubmitted}
                  onClick={onSubmit}
                  type="submit"
                >
                  {wasSubmitted ? `Sign on ${wallet.name}` : 'Submit'}
                </M.SignButton>
              </Form.Buttons>
            )}
          </Form.FormFooter>
        </Form.MainForm>
      )}
    </Formik>
  );
}

export default SendTransaction;
