import styled, { css } from 'styled-components/macro';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgSearch } from 'shared/assets/icons/icon-search.svg';
import { ReactComponent as SvgXMark } from 'shared/assets/icons/icon-x-mark.svg';

export const CurrencySearchWrap = styled.div`
  position: absolute;
  right: 30px;
  top: 15px;
  z-index: 200;
`;

export const SearchLogicWrap = styled.div <{ isOpen: boolean }>`

  display: flex;
  flex-wrap: wrap;
  background: transparent;

  height: 0;
  width: 0;

  padding: 10px;
  transition: opacity 0.3s;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  opacity: 0;

  top: 35px;
  position: absolute;
  right: 0;

  overflow: hidden;

  & ::-webkit-scrollbar-track {
    background-color: var(--highlight);
  }

${(props) =>
    props.isOpen &&
    css`
      opacity: 1;
      height: 360px;
      background: var(--highlight);
      width: 251px;
    `};
`;

export const SearchField = styled.div`
`;

export const List = styled.div`
`;

export const CurrenciesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  height: 310px;
  overflow: scroll;
  align-content: baseline;
  width: 100%;
`;

export const HandlerButton = styled.div`
`;

export const ArrowIcon = styled(SvgArrow) <{ isopen?: string }>`
  width: 25px;
  height: 25px;
  transition: all 0.2s;

  position: absolute;
  right: 0;

  cursor: pointer;
  stroke: currentColor;
  transition: 0.4s;
  stroke: var(--object-primary);

  ${(props) =>
    props.isopen === 'true' &&
    css`
      transform: rotate(180deg)
    `};
`;

export const SearchForm = styled.div`
  position: relative;
  height: 45px;

  border-bottom: 1px solid #D9DFE7;

`;

export const SearchInput = styled.input`
  padding: 5px 40px 10px 30px;
  background: none;
  font-size: var(--font-md);
  border-radius: 5px;
  line-height: 20px;
  width: 100%;
  height: 40px;

  &:focus-visible,
  &:focus {
    outline: none;
  }

  color: var(--object-secondary);
`;

export const IconSearch = styled(SvgSearch)`
  stroke: var(--object-secondary);
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  left: 0;
  margin-top: -10px;
  pointer-events: none;
`;

export const IconClear = styled(SvgXMark)`
  position: absolute;
  stroke: var(--object-secondary);
  right: 10px;
  top: 11px;
  cursor: pointer;
`;

export const CurrencyItem = styled.div`
  margin: 0 5px 5px 0;
  padding: 7px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: var(--font-sm);
  line-height: 10px;
  background-color: var(--border);
  color: var(--object-primary);
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  &.active {
    background-color: var(--accent-primary);
    color: white;
  }
`;

export const CurrencyLogo = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px 0 0;
  background-position: 50%;
  background-size: 10px auto;
`;

export const Delimiter = styled.div`
  width: 200px;
  height: 1px;
  background-color: var(--placeholder);
  margin: 10px 0;
`;

export const ChosenCurrency = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NonChosenCurrency = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
