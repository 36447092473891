type ButtonType = 'primary' | 'outlined' | 'disabled' | 'loading';

interface ButtonProps {
  type: ButtonType;
  rounded?: boolean;
  loading?: boolean;
  onClick?: () => void;
  text: string;
  before?: any;
  after?: any;
  className?: string;
}

const buttonStyles: { [key in ButtonType]: React.CSSProperties } = {
  primary: {
    border: '1px solid var(--accent-primary)',
    backgroundColor: 'var(--accent-primary)',
    color: 'var(--dark-bg)',
  },
  outlined: {
    border: '1px solid var(--accent-primary)',
    color: 'var(--object-primary)',
    backgroundColor: 'transparent',
  },
  disabled: {
    backgroundColor: 'var(--placeholder)',
    border: '1px solid var(--placeholder)',
  },
  loading: {
    backgroundColor: 'var(--placeholder)',
    border: '1px solid var(--placeholder)',
  },
};

export default function Button({
  type,
  loading = false,
  rounded = true,
  onClick,
  before,
  text,
  after,
  className,
}: ButtonProps) {
  const style: React.CSSProperties = {
    pointerEvents: loading ? 'none' : 'auto',
    display: 'flex',
    cursor: 'pointer',
    color: 'var(--primary)',
    fontSize: 'var(--font-sm)',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0.5rem',
    borderRadius: rounded ? '1.5rem' : '0.375rem',
    WebkitFontSmoothing: 'auto',
    ...buttonStyles[loading ? 'loading' : type],
  };

  return (
    <div style={style} className={className} onClick={onClick}>
      {before && (
        <div>
          <div style={{ marginRight: '0.5rem' }}>{before}</div>
        </div>
      )}
      <div>{text}</div>
      {(loading || after) && (
        <div>
          {loading ? (
            <div
              style={{
                marginLeft: '0.5rem',
                width: '1.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                animation: 'spin 1s linear infinite',
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_13_278)">
                  <path
                    d="M12.8332 7.00002C12.8332 10.2217 10.2215 12.8334 6.99984 12.8334C3.77818 12.8334 1.1665 10.2217 1.1665 7.00002C1.1665 3.77836 3.77818 1.16669 6.99984 1.16669"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_13_278">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ) : (
            <div style={{ marginLeft: '0.5rem' }}>{after}</div>
          )}
        </div>
      )}
    </div>
  );
}
