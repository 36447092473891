import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
`;

export const StepIndicator = styled.span`
  padding-right: 8px;
`;

export const ModalHeader = styled.span`
  padding-right: 8px;
`;

export const Button = styled.button<{ disabled?: boolean }>`
  font-size: var(--font-sm);
  line-height: 20px;
  text-transform: uppercase;
  background-color: var(--accent-primary);
  color: var(--base);
  padding: 10px;
  font-weight: 700;
  width: auto;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
  display: flex;

  &:hover {
    background: var(--object-primary);
  }
  & + & {
    margin: 0 0 0 10px;
  }
  ${(prop) =>
    prop.disabled &&
    css`
      background: var(--object-secondary)
      color: var(--base);
      pointer-events: none;
    `}
`;

export const SignButton = styled(Button)`
  min-width: 136px;
  white-space: nowrap;
  justify-content: center;
`;

export const CancelButton = styled(Button)`
  background: none;
  color: var(--accent-primary);

  &:hover {
    background: none;
    color: var(--object-primary);
  }
`;
