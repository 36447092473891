/* eslint-disable */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Position } from '../../../types';
import { StatusType } from 'shared/types';
import {
  _removeTrailingZeros,
  formatAccount,
  formatNumberWithoutExponent,
} from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { CurrenciesStoreInstance } from 'services';
import Decimal from 'decimal.js-light';
import { MAX_INTEGER } from 'ethereumjs-util';

function formatPriceEntry(price: string) {
  // Convert to a number if it's a string with commas
  let numericPrice =
    typeof price === 'string' ? parseFloat(price.replace(/,/g, '')) : price;

  // Check if the value is valid
  if (isNaN(numericPrice)) {
    throw new Error('Invalid price value');
  }

  // Handle large numbers (absolute value >= 1000) without decimals
  if (Math.abs(numericPrice) >= 1000) {
    return Math.floor(numericPrice).toLocaleString('en-US');
  }

  // Handle very small numbers (absolute value < 0.001)
  if (Math.abs(numericPrice) > 0 && Math.abs(numericPrice) < 0.001) {
    let formatted = Math.abs(numericPrice)
      .toFixed(8) // Display up to 8 decimals
      .replace(/0+$/, '') // Remove trailing zeros
      .replace(/\.$/, ''); // Remove trailing decimal point if present
    return numericPrice < 0 ? `-${formatted}` : formatted; // Add negative sign if necessary
  }

  // Adjust decimal places for smaller numbers
  let formattedPrice = Math.abs(numericPrice).toFixed(6); // Start with 6 decimal places

  // Ensure it fits within 4 significant digits
  formattedPrice = parseFloat(formattedPrice).toString();
  let parts = formattedPrice.split('.');

  if (parts[0].length >= 4) {
    // If the integer part already has 4 or more digits, truncate the decimals
    return numericPrice < 0 ? `-${parts[0]}` : parts[0];
  }

  if (parts[1]) {
    // Limit the decimal part to ensure 4 significant digits total
    let significantDecimals = 4 - parts[0].length;
    formattedPrice = `${parts[0]}.${parts[1].slice(0, significantDecimals)}`;
  }

  let finalResult = _removeTrailingZeros(formattedPrice);
  return numericPrice < 0 ? `-${finalResult}` : finalResult;
}

interface PositionRowProps {
  position: Position;
  viewInfo: any;
  columnsSettings: any;
}

const isStablecoin = (currency: string) => {
  return ['USDC', 'USDT'].includes(currency);
};

export const PositionRow = ({
  position,
  viewInfo,
  columnsSettings,
}: PositionRowProps) => {
  const { rates } = useContext(AppContext);
  const { getCurrencyPrecision, getCurrencyValue } = CurrenciesStoreInstance;

  const {
    external_id,
    pair,
    type,
    size,
    price_entry,
    time_entry,
    origin_funding,
    origin_pnl_unrealized,
    origin_pnl_realized,
    mark_price,
    pnl_currency,
    instrument_type,
    pnl_realized,
    pnl_unrealized,
    updated_at,
    size_type,
    price_currency,
    size_currency,
  } = position;

  const timeEntryDate = time_entry
    ? format(parseISO(time_entry), 'yyyy-MM-dd')
    : '-';
  const timeEntryTime = time_entry
    ? format(parseISO(time_entry), 'HH:mm:ss')
    : '-';

  const updateTimeDate = updated_at
    ? format(parseISO(updated_at), 'yyyy-MM-dd')
    : '-';
  const updateTimeTime = updated_at
    ? format(parseISO(updated_at), 'HH:mm:ss')
    : '-';

  return (
    <S.Row
      key={external_id}
      data-id={external_id}
      style={{ paddingRight: '35px' }}
    >
      <S.PositionIntro>
        {columnsSettings.activeColumns.includes('market_pair') && (
          <S.DateCol>{pair}</S.DateCol>
        )}
        {columnsSettings.activeColumns.includes('instrument_type') && (
          <S.DateCol>{instrument_type}</S.DateCol>
        )}
        {columnsSettings.activeColumns.includes('type') && (
          <S.TxCol>
            {type === 'LONG' ? <S.IconLong /> : <S.IconShort />}
            {type}
          </S.TxCol>
        )}
        {columnsSettings.activeColumns.includes('size') && (
          <S.FromCol>
            {formatPriceEntry(size)} {size_currency}
          </S.FromCol>
        )}
        {columnsSettings.activeColumns.includes('current_value') && (
          <S.StatusCol>
            {mark_price !== null
              ? getCurrencyValue({
                  // @ts-ignore
                  currency: viewInfo?.id === 'crypto' ? 'usd' : viewInfo?.id,
                  value: new Decimal(size !== null ? size : 0)
                    .mul(size_currency === 'USD' ? 1 : mark_price)
                    .div(viewInfo?.divisor)
                    .toFixed(),
                  // @ts-ignore
                  minprecision: getCurrencyPrecision(viewInfo?.id === 'crypto' ? 'usd' : viewInfo?.id),
                })
              : getCurrencyValue({
                  // @ts-ignore
                  currency: viewInfo?.id === 'crypto' ? 'usd' : viewInfo?.id,
                  value: new Decimal(size !== null ? size : 0)
                    .mul(size_currency === 'USD' ? 1 : price_entry)
                    .plus(origin_pnl_unrealized)
                    .div(viewInfo?.divisor)
                    .toFixed(),
                  // @ts-ignore
                  minprecision: getCurrencyPrecision(viewInfo?.id === 'crypto' ? 'usd' : viewInfo?.id),
                })}
            {viewInfo?.divisor_label} USD
          </S.StatusCol>
        )}
        {columnsSettings.activeColumns.includes('price_entry') && (
          <S.ToCol>
            {`${formatPriceEntry(price_entry)} ${price_currency}`}
          </S.ToCol>
        )}
        {columnsSettings.activeColumns.includes('opening_time') && (
          <S.AmountCol>
            {time_entry ? (
              <>
                <div>{timeEntryDate}</div>
                <span style={{ color: 'var(--object-secondary)' }}>
                  {timeEntryTime}
                </span>{' '}
              </>
            ) : (
              <div style={{ paddingLeft: '26px' }}>-</div>
            )}
          </S.AmountCol>
        )}
        {columnsSettings.activeColumns.includes('updated_at') && (
          <S.AmountCol>
            {updated_at ? (
              <>
                <div>{updateTimeDate}</div>
                <span style={{ color: 'var(--object-secondary)' }}>
                  {updateTimeTime}
                </span>{' '}
              </>
            ) : (
              <div style={{ paddingLeft: '26px' }}>-</div>
            )}
          </S.AmountCol>
        )}
        {columnsSettings.activeColumns.includes('accumulated_funding') && (
          <S.ConfirmationsCol
            style={{
              minWidth: '150px',
              color:
                Number(origin_funding) < 0 ? 'var(--error)' : 'var(--success)',
            }}
          >
            {getCurrencyValue({
              // @ts-ignore
              currency: viewInfo?.id,
              value: new Decimal(origin_funding !== null ? origin_funding : 0)
                .mul(
                  viewInfo?.id === 'crypto' || isStablecoin(pnl_currency)
                    ? 1
                    : mark_price !== null
                    ? mark_price
                    : rates?.[viewInfo?.currency]?.[pnl_currency],
                )
                .div(viewInfo?.divisor)
                .toFixed(),
              // @ts-ignore
              minprecision: getCurrencyPrecision(viewInfo?.id),
            })}
            {viewInfo?.divisor_label}{' '}
            {viewInfo?.id === 'crypto' ? pnl_currency : viewInfo?.currency}
          </S.ConfirmationsCol>
        )}
        {columnsSettings.activeColumns.includes('pnl_realized') && (
          <S.ConfirmationsCol
            style={{
              color:
                Number(origin_pnl_realized) < 0
                  ? 'var(--error)'
                  : 'var(--success)',
            }}
          >
            {getCurrencyValue({
              // @ts-ignore
              currency: viewInfo?.id,
              value: new Decimal(
                origin_pnl_realized !== null ? origin_pnl_realized : 0,
              )
                .mul(
                  viewInfo?.id === 'crypto' || isStablecoin(pnl_currency)
                    ? 1
                    : mark_price !== null
                    ? mark_price
                    : rates?.[viewInfo?.currency]?.[pnl_currency],
                )
                .div(viewInfo?.divisor)
                .toFixed(),
              // @ts-ignore
              minprecision: getCurrencyPrecision(viewInfo?.id),
            })}
            {viewInfo?.divisor_label}{' '}
            {viewInfo?.id === 'crypto' ? pnl_currency : viewInfo?.currency}
          </S.ConfirmationsCol>
        )}
        {columnsSettings.activeColumns.includes('pnl_unrealized') && (
          <S.ConfirmationsCol
            style={{ 
              paddingRight: '20px',
              // marginRight: '10px',
              color:
                Number(origin_pnl_unrealized) < 0
                  ? 'var(--error)'
                  : 'var(--success)',
            }}
          >
            {getCurrencyValue({
              // @ts-ignore
              currency: viewInfo?.id,
              value: new Decimal(
                origin_pnl_unrealized !== null ? origin_pnl_unrealized : 0,
              )
                .mul(
                  viewInfo?.id === 'crypto' || isStablecoin(pnl_currency)
                    ? 1
                    : mark_price !== null
                    ? mark_price
                    : rates?.[viewInfo?.currency]?.[pnl_currency],
                )
                .div(viewInfo?.divisor)
                .toFixed(),
              // @ts-ignore
              minprecision: getCurrencyPrecision(viewInfo?.id),
            })}
            {viewInfo?.divisor_label}{' '}
            {viewInfo?.id === 'crypto' ? pnl_currency : viewInfo?.currency}
          </S.ConfirmationsCol>
        )}
      </S.PositionIntro>
    </S.Row>
  );
};
