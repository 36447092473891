import { IconChevron } from 'features/Transactions/view/components/shared/ManualResolve';
import * as S from '../styled';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { useContext, useEffect, useState } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { ConfirmUserModal } from './ConfirmChangesModal';
import { useObserveElementWidth } from '../hooks/useObserveElementWidth';

const VoteMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 1, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
});

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const CurrencyMask = createNumberMask({
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  // decimalSymbol: '.',
  // decimalLimit: 2, // how many digits allowed after the decimal
  allowNegative: false,
  allowLeadingZeroes: false,
});

const Select = ({ value, onChange }: any) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <ListboxButton
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid var(--border)',
              borderRadius: '5px',
              padding: '8px 10px',
              textAlign: 'left',
              width: '117px',
              marginLeft: '10px',
              fontWeight: 'bold',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  marginLeft: '2px',
                  textTransform: 'capitalize',
                }}
              >
                {value === 'DAY' ? 'Daily' : 'Weekly'}
              </div>
            </div>
            <div>
              <IconChevron
                style={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </div>
          </ListboxButton>
          <ListboxOptions
            style={{
              width: '117px',
              background: 'var(--base)',
              position: 'absolute',
              cursor: 'pointer',
              top: '100%',
              right: 0,
              border: '1px solid var(--border)',
              borderRadius: '5px',
            }}
          >
            <ListboxOption
              className="hoverable-option"
              style={{
                padding: '10px 10px',
                cursor: 'pointer',
                display: 'flex',
                fontWeight: 'bold',
                alignItems: 'center',
              }}
              key={'DAY'}
              value={'DAY'}
            >
              Daily
            </ListboxOption>
            <ListboxOption
              className="hoverable-option"
              style={{
                padding: '10px 10px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
              key={'WEEK'}
              value={'WEEK'}
            >
              Weekly
            </ListboxOption>
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  );
};

export const EditUserModal = ({ id, email, currentValues, payments }: any) => {
  const { width, ref } = useObserveElementWidth({});
  const { width: singleTxLimitWidth, ref: singleTxLimitRef } =
    useObserveElementWidth({});
  const { openModal, appSettings } = useContext(AppContext);
  const [paymentType, setPaymentType] = useState(currentValues?.period_type);
  const [singleTxLimit, setSingleTxLimit] = useState(
    currentValues?.single_transaction_limit,
  );
  const [voteWeight, setVoteWeight] = useState(currentValues?.vote_weight);
  const [paymentLimit, setPaymentLimit] = useState(currentValues?.period_limit);
  const isUnchanged =
    paymentType == currentValues?.period_type &&
    (singleTxLimit ==
      USDollar.format(currentValues?.single_transaction_limit) ||
      singleTxLimit == currentValues?.single_transaction_limit) &&
    voteWeight == currentValues?.vote_weight &&
    (paymentLimit == USDollar.format(currentValues?.period_limit) ||
      paymentLimit == currentValues?.period_limit);

  const onReviewChanges = () => {
    openModal({
      title: 'Confirm changes',
      titleRightText: 'Step 2 of 2',
      outsideClickClose: false,
      component: () => (
        <ConfirmUserModal
          id={id}
          email={email}
          payments={payments}
          oldValues={currentValues}
          newValues={{
            period_type: paymentType,
            single_transaction_limit: String(
              parseInt(singleTxLimit?.replace(/[$,]/g, '')),
            ),
            vote_weight: voteWeight,
            period_limit: String(parseInt(paymentLimit?.replace(/[$,]/g, ''))),
          }}
        />
      ),
    });
  };

  useEffect(() => {
    setPaymentLimit(USDollar.format(paymentLimit));
  }, []);

  const voteWeightError =
    voteWeight > appSettings.proposal_votes_value_to_approve;
  const paymentLimitError = !paymentLimit.length;

  const isError = voteWeightError || paymentLimitError;

  return (
    <S.EditUserModalWrapper>
      <S.ModalItem>
        <S.InputLabel>User</S.InputLabel>
        <S.StaticText>{email}</S.StaticText>
      </S.ModalItem>
      <S.ModalItem>
        <S.InputLabel>Vote weight</S.InputLabel>
        <S.ModalInput
          placeholder="Unset"
          error={voteWeightError}
          mask={VoteMask}
          type="text"
          value={voteWeight}
          onChange={(e: any) => setVoteWeight(e?.target?.value)}
        ></S.ModalInput>
        {voteWeightError && (
          <S.InputError>
            Vote weight can not be more than{' '}
            {appSettings.proposal_votes_value_to_approve}
          </S.InputError>
        )}
      </S.ModalItem>
      <S.ModalItem style={{ position: 'relative' }}>
        <S.InputLabel>Single transaction limit</S.InputLabel>
        <span
          style={{
            whiteSpace: 'pre',
            position: 'absolute',
            top: 0,
            visibility: 'hidden',
            userSelect: 'none',
            fontSize: 'var(--font-sm)',
          }}
          ref={singleTxLimitRef}
        >
          {USDollar.format(
            Number(appSettings?.user_single_transaction_limit_default),
          )}
        </span>
        {(!singleTxLimit || singleTxLimit === null) && (
          <div
            style={{
              position: 'absolute',
              left: `calc(16px + ${singleTxLimitWidth}px)`,
              top: '33px',
              zIndex: 999999,
              color: 'var(--object-secondary',
              fontWeight: 'bold',
            }}
          >
            (Default)
          </div>
        )}
        <S.ModalInput
          whitePlaceholder={true}
          placeholder={`${USDollar.format(
            Number(appSettings?.user_single_transaction_limit_default),
          )}`}
          mask={CurrencyMask}
          type="text"
          value={singleTxLimit}
          onChange={(e: any) => setSingleTxLimit(e?.target?.value)}
        ></S.ModalInput>
      </S.ModalItem>
      <S.ModalItem>
        <S.InputLabel>Payment limit</S.InputLabel>
        <S.PaymentLimitInputs>
          <span
            style={{
              whiteSpace: 'pre',
              position: 'absolute',
              top: 0,
              visibility: 'hidden',
              userSelect: 'none',
              fontSize: 'var(--font-sm)',
            }}
            ref={ref}
          >
            {paymentLimit}
          </span>
          <div
            style={{
              position: 'absolute',
              left: `calc(36px + ${width}px)`,
              zIndex: 999999,
              color: 'var(--object-secondary',
              fontWeight: 'bold',
            }}
          >{`(${USDollar.format(payments)} spent)`}</div>
          <S.ModalInput
            error={paymentLimitError}
            mask={CurrencyMask}
            type="text"
            value={paymentLimit}
            onChange={(e: any) => setPaymentLimit(e?.target?.value)}
          ></S.ModalInput>
          <Select value={paymentType} onChange={setPaymentType}></Select>
        </S.PaymentLimitInputs>
        {paymentLimitError && (
          <S.InputError>Payment limit can not be empty</S.InputError>
        )}
      </S.ModalItem>
      <S.Divider />
      <S.ActionButtons>
        <S.Cancel onClick={() => openModal(null)}>Cancel</S.Cancel>
        <S.ActionButton
          disabled={isUnchanged || isError}
          onClick={onReviewChanges}
        >
          Review changes
        </S.ActionButton>
      </S.ActionButtons>
    </S.EditUserModalWrapper>
  );
};
