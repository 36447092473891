import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import AppContext from 'shared/contexts/AppContext';
import * as S from './styled';

export const Modal = () => {
  const { modal, openModal } = useContext(AppContext);

  if (!modal) return null;

  const {
    title,
    titleType,
    width,
    titleRightText,
    component: Component,
    onClose: customClose,
    outsideClickClose = true,
  } = modal;

  const onStopPropagation: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const onClose = () => {
    if (customClose) {
      customClose();
    }
    openModal(null);
  };

  const wrapper = (
    <S.ModalWrap onClick={outsideClickClose ? onClose : () => {}}>
      <S.AdvancedWrap>
        <S.ModalPanel
          onClick={onStopPropagation}
          initial={{ opacity: 0, y: 16 }}
          animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, y: 16, transition: { duration: 0.3 } }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ width }}
        >
          <S.ModalHeader isExist={!title}>
            <S.ModalTitle titleType={titleType}>
              <span>{title}</span>
              <span style={{ color: 'var(--placeholder)', marginRight: '40px', fontWeight: 'normal', fontSize: 'var(--font-xl)' }}>{titleRightText}</span>
            </S.ModalTitle>
            <S.CloseButton onClick={onClose}>
              <S.CloseIcon />
            </S.CloseButton>
          </S.ModalHeader>
          <Component />
        </S.ModalPanel>
      </S.AdvancedWrap>
    </S.ModalWrap>
  );

  return createPortal(wrapper, document.body);
};
