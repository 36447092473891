import { memo, useState } from 'react';

import * as S from 'features/Orders/view/styled';
import {
  IconSettings,
  IconSettingsFilled,
} from 'shared/components/AbstractComponents';
import Tippy from '@tippyjs/react';
import { Checkbox } from 'shared/components';
import { CheckboxLabel } from '../Filters/styled';
import { capitalize } from 'shared/helpers/format';

export const OrdersHeader = ({
  tab,
  columnsSettings,
}: {
  tab: string;
  columnsSettings: any;
}) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  return (
    <S.PositionsHeaderWrapper>
      <S.Header>
        <S.HeaderRow style={{ paddingRight: '35px' }}>
          {columnsSettings.activeColumns.includes('market_pair') && (
            <S.DateCol style={{ fontFamily: 'var(--font-main)' }}>
              Market pair
            </S.DateCol>
          )}
          {columnsSettings.activeColumns.includes('side') && (
            <S.TxCol style={{ fontFamily: 'var(--font-main)' }}>Side</S.TxCol>
          )}
          {columnsSettings.activeColumns.includes('type') && (
            <S.FromCol style={{ fontFamily: 'var(--font-main)' }}>
              Type
            </S.FromCol>
          )}
          {columnsSettings.activeColumns.includes('size_|_filled') && (
            <S.StatusCol style={{ fontFamily: 'var(--font-main)', textAlign: 'left' }}>
              Size | Filled
            </S.StatusCol>
          )}
          {columnsSettings.activeColumns.includes('price') && (
            <S.StatusCol style={{ fontFamily: 'var(--font-main)', textAlign: 'left' }}>
              Price
            </S.StatusCol>
          )}
          {tab === 'open_orders' &&
            columnsSettings.activeColumns.includes('creation_time') && (
              <S.StatusCol
                style={{ fontFamily: 'var(--font-main)', textAlign: 'left' }}
              >
                Creation time
              </S.StatusCol>
            )}
          {tab === 'history' &&
            columnsSettings.activeColumns.includes('execution_time') && (
              <S.StatusCol
                style={{ fontFamily: 'var(--font-main)', textAlign: 'left' }}
              >
                Execution time
              </S.StatusCol>
            )}
          {tab === 'history' &&
            columnsSettings.activeColumns.includes('status') && (
              <S.ConfirmationsCol style={{ fontFamily: 'var(--font-main)' }}>
                Status
              </S.ConfirmationsCol>
            )}
          {tab === 'history' &&
            columnsSettings.activeColumns.includes('total') && (
              <S.ConfirmationsCol
                style={{ fontFamily: 'var(--font-main)', textAlign: 'left' }}
              >
                Total
              </S.ConfirmationsCol>
            )}
        </S.HeaderRow>
        <Tippy
          theme="interactive"
          offset={[12, 11]}
          placement="bottom-end"
          delay={[0, 0]}
          onClickOutside={hide}
          zIndex={9999999}
          interactive={true}
          visible={visible}
          content={
            <div>
              <div
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  fontSize: 'var(--font-sm)',
                  textTransform: 'uppercase',
                  marginBottom: '10px',
                }}
              >
                Columns
              </div>
              <div>
                {columnsSettings.columns.map((column: string) => {
                  const checked =
                    columnsSettings.activeColumns.includes(column);
                  return (
                    <CheckboxLabel style={{ marginBottom: '5px' }} key={column}>
                      <Checkbox
                        checked={checked}
                        onChange={columnsSettings.onChange}
                        type="checkbox"
                        name={column}
                        title={column}
                        value={column}
                      />
                      <span>
                        {column === 'size_|_filled'
                          ? 'Size | Filled'
                          : capitalize(column.replace('_', ' '))}
                      </span>
                    </CheckboxLabel>
                  );
                })}
              </div>
            </div>
          }
        >
          {visible ? (
            <IconSettingsFilled
              onClick={hide}
              style={{
                position: 'absolute',
                right: '12px',
                bottom: '10px',
                stroke: 'var(--accent-primary)',
                cursor: 'pointer',
              }}
            />
          ) : (
            <IconSettings
              onClick={show}
              style={{
                position: 'absolute',
                right: '12px',
                bottom: '10px',
                stroke: 'var(--accent-primary)',
                cursor: 'pointer',
              }}
            />
          )}
        </Tippy>
      </S.Header>
    </S.PositionsHeaderWrapper>
  );
};
