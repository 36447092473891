import styled, { css } from 'styled-components/macro';

import { ReactComponent as SvgError } from 'shared/assets/icons/icon-warning.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const IFrame = styled.iframe`
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: none;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  `;

export const LoaderText = styled.span`
  font-size: var(--font-xxxxl);
  position: absolute;
  top: 58%;
`;

export const ErrorMessage = styled.span`
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

export const ErrorTitle = styled.span`
  font-weight: 400;
  font-size: var(--font-xxxxxl);
`;

export const ErrorHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorIcon = styled(SvgError)`
  width: 22px;
  height: 22px;
  margin-right: 7px;
`;

export const ModalLabel = styled.span`
  font-size: var(--font-xxl);
  font-weight: bold;
`;

export const ErrorWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Button = styled.button<{ disabled?: boolean }>`
  font-size: var(--font-sm);
  line-height: 20px;
  text-transform: uppercase;
  background-color: var(--accent-primary);
  color: var(--base);
  padding: 10px;
  font-weight: 700;
  width: auto;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
  display: flex;

  &:hover {
    background: var(--object-primary);
  }
  & + & {
    margin: 0 0 0 10px;
  }
  ${(prop) =>
    prop.disabled &&
    css`
      background: var(--object-secondary)
      color: var(--base);
      pointer-events: none;
    `}
`;

export const SignButton = styled(Button)`
  min-width: 136px;
  white-space: nowrap;
  justify-content: center;
`;

export const CancelButton = styled(Button)`
  background: none;
  color: var(--accent-primary);

  &:hover {
    background: none;
    color: var(--object-primary);
  }
`;

export const Buttons = styled.div`
  display: flex;
  height: 40px;
  font-size: var(--font-sm);
  width: 100%;
  justify-content: end;
`;

export const Dialog = styled.div`
  margin-top: 20px;
`;

export const Form = styled.div`
`;

export const Bottom = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
`;

export const BottomText = styled.div`
  display: flex;
  color: var(--object-primary);
  
`;
