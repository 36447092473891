import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const Wrap = styled(motion.div)`
  min-height: calc(100% - 74px);
  width: 100%;
  min-width: 1010px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 0;
`;
export const Table = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  padding: 10px 20px;
  background: var(--base);
  border-radius: 5px;
  position: relative;
  z-index: 1;

  &:nth-child(even) {
    background-color: var(--highlight);
  }
`;
export const Col = styled.div`
  width: 33%;
  min-width: 33%;
  font-size: var(--font-xxs);
  line-height: 20px;
`;
export const Header = styled(Row)`
  border: 1px solid var(--border);
  z-index: 2;

  ${Col} {
    color: var(--accent-primary);
    text-transform: uppercase;
    font-size: var(--font-xxxs);
    line-height: 20px;
    font-weight: 700;
  }
`;
export const AssetCol = styled(Col)`
  display: flex;
  align-items: center;

  span {
    margin: 0 0 0 10px;
  }
`;
export const Digits = styled.span`
  font-family: var(--font-monospaced);
`;
