import styled, { css, keyframes } from 'styled-components/macro';
import { motion } from 'framer-motion';

import { TabWrap } from 'shared/components/Tab/Tab';
import { ReactComponent as SvgCopy } from 'shared/assets/icons/icon-copy.svg';
import { ReactComponent as SvgApproved } from 'shared/assets/icons/icon-whitelist-yes.svg';
import { ReactComponent as SvgRejected } from 'shared/assets/icons/icon-whitelist-no.svg';
import { ReactComponent as SvgVoteUp } from 'shared/assets/icons/icon-vote-up.svg';
import { ReactComponent as SvgVoteDown } from 'shared/assets/icons/icon-vote-down.svg';
import { ReactComponent as SvgVote } from 'shared/assets/icons/icon-chevron-rounded.svg';
import { ReactComponent as SvgApprovedStatus } from 'shared/assets/icons/icon-approved.svg';
import { ReactComponent as SvgRejectedStatus } from 'shared/assets/icons/icon-rejected.svg';
import { ReactComponent as SvgClock } from 'shared/assets/icons/icon-clock.svg';
import { ReactComponent as SvgProcessing } from 'shared/assets/icons/icon-processing.svg';
import { ReactComponent as SvgConfirmations } from 'shared/assets/icons/icon-confirmations.svg';
import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgArrowAside } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgFiltersIcon } from 'shared/assets/icons/filters-icon.svg';
import { ReactComponent as SvgArrowUp } from 'shared/assets/icons/icon-arrowup.svg';
import { ReactComponent as SvgArrowDown } from 'shared/assets/icons/icon-arrowdown.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const PositionsHeaderWrapper = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  z-index: 1;
`;

export const ProposalsHeaderWrapper = styled.div`
  background-color: var(--base);
  grid-area: 2 / 1 / 3 / 3;
  z-index: 1;
`;

export const NotFoundWrapper = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  padding-top: 54px;
  padding-bottom: 48.5px;
`;

export const Wrap = styled.div`
  min-height: 100%;
  flex: 1;
  overflow: visible;
  position: relative;
  background: var(--base);
`;

export const LoadMoreButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--base);
  color: var(--accent-primary);
  font-size: var(--font-xxxs);
  font-family: var(--font-main)
  font-weight: 700;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: var(--object-primary);
  }
`;

export const NotFound = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--base);
  align-items: center;
  justify-content: center;
  font-size: var(--font-xxl);
  line-height: 40px;
  color: var(--object-secondary);
  font-weight: 400;
  border-radius: 10px;
`;

export const Tabs = styled(TabWrap)`
  padding: 15px 20px;
  padding-bottom: 5px;
  grid-area: 1 / 1 / 2 / 3;
  background-color: var(--base);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  font-family: var(--font-main)
  font-size: var(--font-md);
  line-height: 17px;
  font-weight: 700;
`;

export const Table = styled(motion.div)``;

export const PositionsList = styled.div`
  // background-color: white;
  overflow: auto;
  overflow-x: hidden;
`;

export const ProposalsSpacer = styled.div`
  width: 100%;
  height: 54px;
`;

export const Header = styled.header`
  border: 1px solid var(--border);
  border-top: none;
  background: var(--base);
  margin: 0;
  border-radius: 0 0 5px 5px;
  padding: 0;
  position: relative;
  z-index: 3;
  padding-top: 24px;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  & > div {
    font-size: var(--font-xxxs);
    line-height: 11px;
    text-transform: uppercase;
    color: var(--accent-primary);
    font-weight: 700;
    // padding: 0;
  }
`;

export const HeaderTitle = styled.div`
  font-size: var(--font-md);
  line-height: 17px;
  font-weight: 700;
  font-family: var(--font-main)
  color: var(--object-primary);
  padding: 10px 20px;
`;

export const Row = styled.div`
  display: flex;
  padding: 10px 20px;
  margin: 0;
  flex-wrap: wrap;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--base);
  position: relative;
  font-family: var(--font-monospaced);
  min-height: 44px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    z-index: 0;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }

  &:nth-child(even) {
    background: var(--highlight);
  }

  &:last-child {
    margin: 0;
  }
`;

export const CurrencyInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Col = styled.div`
  width: 100%;
  font-family: Roboto Mono;
  font-size: var(--font-xxs);
  // display: flex;
  // align-items: center;
  height: 100%;
  padding: 0 5px;
`;

export const GrayInlineText = styled.span`
  color: var(--object-secondary);
`;

export const DateCol = styled(Col)`
  width: 90px;
  max-width: 90px;
  min-width: 90px;
  font-size: var(--font-xxs);
  line-height: 12px;
`;

export const TxCol = styled(Col)`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  font-size: var(--font-xxs);
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FromCol = styled(Col)`
  display: flex;
  max-width: 120px;
  min-width: 120px;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: right;
`;

export const FromExchangeIcon = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  max-width: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  margin: 2px 5px 0 0;
`;

export const FromExchangeName = styled.div`
  font-size: var(--font-xxs);
  color: var(--object-secondary);
  line-height: 10px;
`;

export const FromAccountName = styled.div`
  font-size: var(--font-xxs);
  line-height: 14px;
  margin: 0 0 3px 0;
  width: 100%;
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ToCol = styled(FromCol)`
  text-align: right;
  padding-right: 20px;
`;

export const ThresholdExchangeIcon = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  min-width: 10px;
  max-width: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  margin: 0 5px;
`;

export const ToExchangeIcon = styled(FromExchangeIcon)``;

export const ToExchangeName = styled(FromExchangeName)``;

export const ToAccountName = styled(FromAccountName)``;

export const ConfirmationsCol = styled(Col)`
  font-size: var(--font-xxs);
  line-height: 14px;
  max-width: 120px;
  min-width: 120px;
  // display: flex;
  align-items: center;
  // justify-content: flex-end;
  text-align: right;

  span {
    font-family: var(--font-monospaced);
    text-transform: uppercase;
  }
`;

export const AmountCol = styled(Col)`
  font-size: var(--font-xxs);
  line-height: 14px;
  max-width: 100px;
  min-width: 100px;
  flex-direction: column;
  // padding-left: 10px;
  display: flex;

  span {
    font-family: var(--font-monospaced);
    text-transform: uppercase;
  }
`;

export const StatusCol = styled(Col)`
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  text-align: right;
`;

export const StatusProposalsCol = styled(Col)`
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  text-align: right;
`;

export const CurrencyLogo = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px 0 0;
  background-position: 50%;
  background-size: 10px auto;
`;

export const CurrencyItem = styled.div`
  margin: 0 5px 0 0;
  margin-top: 5px;
  padding: 5px 7px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: var(--font-xxs);
  line-height: 10px;
  background-color: var(--border);
  color: var(--object-primary);
  user-select: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  height: 22px;

  &.active {
    background-color: var(--accent-primary);
    color: white;
  }
`;

export const CurrencyCounter = styled.div`
  color: var(--accent-primary);
  font-weight: 700;
  font-size: var(--font-xxs);
`;

export const PositionIntro = styled.div`
  display: flex;
  align-items: center;
  // padding-top: 4px;
  justify-content: space-between;
  width: 100%;
  // cursor: pointer;
  position: relative;
  z-index: 1;
`;
export const SideWrap = styled.div``;
export const TxId = styled.span`
  font-family: var(--font-monospaced);
`;

export const PositionDetail = styled.div`
  display: flex;
  padding: 20px 0 0 0;
  margin: 10px 0;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
`;

export const PositionDetailWrap = styled.div`
  display: flex;
  width: 100%;
`;

export const Left = styled.div`
  width: 100%;
  max-width: 50%;
  min-width: 50%;
  position: relative;

  ${SideWrap} {
    padding: 0 25px 0 0;
  }
`;

export const Right = styled(Left)`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 1px;
    background: var(--border);
    pointer-events: none;
  }
  ${SideWrap} {
    padding: 0 0 0 25px;
  }
`;

export const FullWidth = styled.div`
  width: 100%;
  padding: 20px 0 0 0;
`;

export const StatusBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding: 0;
  user-select: none;
  font-family: var(--font-monospaced);
`;

export const StatusBarLineItem = styled.div<{ loading?: boolean }>`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  padding: 5px 0 13px 20px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
  }
  &:before {
    top: 6px;
    left: 0;
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0);
    border: 2px solid var(--border);
    border-radius: 10px;
  }
  &:after {
    bottom: 0;
    left: 4px;
    width: 2px;
    height: 10px;
    border-radius: 10px;
    background: var(--border);
  }

  &.awaiting {
    &:before {
      border: 2px solid var(--warning);
    }
    h4 {
      span {
        color: var(--object-primary);
      }
    }
  }

  &.failed {
    &:before {
      background: var(--error);
      border: 2px solid var(--error);
    }
    h4 {
      span {
        color: var(--error);
      }
    }
  }

  &.active {
    &:before {
      border: 2px solid var(--success);
    }
    h4 {
      span {
        color: var(--object-primary);
      }
    }
  }
  &.completed {
    &:before {
      background: var(--success);
      border: 2px solid var(--success);
    }
    &:after {
      background: var(--success);
    }

    h4 {
      span {
        color: var(--success);
      }
    }
  }

  ${(prop) =>
    prop.loading &&
    css`
      &:before {
        border-top: 2px solid rgba(255, 255, 255, 0) !important;
        animation: ${rotate} 2s linear infinite;
      }
    `}

  &:last-of-type {
    padding: 5px 0 5px 20px;
    &:after {
      display: none;
    }
  }
`;

export const StatusBarLineRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;
export const StatusBarLineDateTooltip = styled.div``;
export const StatusBarLineDate = styled.div`
  max-width: 100px;
  font-size: var(--font-xxs);
  color: var(--placeholder);
  line-height: 14px;
  text-align: right;
  cursor: pointer;
`;
export const StatusBarLineTitle = styled.h4`
  margin: 0;
  font-size: var(--font-xxs);
  line-height: 12px;
  font-weight: normal;
  color: var(--placeholder);

  span {
    font-weight: 700;
    margin: 0 10px 0 0;
  }
`;

export const StatusBarLineFinalTitle = styled(StatusBarLineTitle)`
  left: auto;
  right: 0;
`;
export const VoteWeightWrap = styled.footer`
  width: 100%;
  padding: 10px 0 10px 20px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    color: var(--accent-primary);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    left: 20px;
    height: 1px;
    background: var(--border);
  }

  p {
    font-size: var(--font-xxs);
    color: var(--object-primary);
    margin: 0 0 5px 0;
  }
`;
const VoteButtonCss = css`
  padding: 5px 0;
  margin: 0;
  border: none;
  background: none;
  color: var(--object-primary);
  font-size: var(--font-sm);
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--object-primary) !important;
  }

  &:disabled {
    color: var(--placeholder) !important;
  }
`;
export const VoteUpButton = styled.button`
  ${VoteButtonCss}
  color: var(--success);
`;
export const ThresholdViolation = styled.div`
  font-size: var(--font-xxs);
  color: var(--error);
  position: relative;
  margin: 5px 0;
  padding-left: 10px;

  & > span {
    color: var(--object-primary);
  }

  &::before {
    left: 0;
    content: '';
    height: 100%;
    width: 1px;
    background-color: var(--error);
    position: absolute;
  }
`;
export const VoteDownButton = styled.button`
  ${VoteButtonCss}
  color: var(--error);
`;
export const ErrorText = styled.p`
  color: var(--error) !important;
  user-select: text;
  margin-top: 6px !important;
`;
//

export const PositionDetailItem = styled.div`
  margin: 0 0 20px 0;

  &:last-child {
    margin: 0;
  }
`;

export const AccountData = styled.div`
  display: flex;
`;

export const PositionDetailLabel = styled.div`
  font-size: var(--font-xxs);
  line-height: 13px;
  font-family: var(--font-monospaced);
  margin: 0 0 5px 0;
  width: 100%;
  color: var(--object-secondary);
`;

export const PositionDetailValue = styled.div`
  font-weight: 400;
  font-size: var(--font-xxs);
  line-height: 13px;
  font-family: var(--font-monospaced);

  span {
    font-weight: 300;
    margin: 0 0 0 5px;
  }
`;

export const PositionNote = styled.div<{ status: string }>`
  position: relative;
  padding: 0 10px;
  line-height: 14px;
  width: 100%;
  margin: 15px 0 0 0;
  font-size: var(--font-sm);
  font-weight: 700;
  text-align: center;

  ${(prop) =>
    prop.status === 'rejected' &&
    css`
      text-align: left;
      border-left: 2px solid var(--warning);
    `}

  ${(prop) =>
    prop.status === 'canceled' &&
    css`
      text-align: left;
      border-left: 2px solid var(--warning);
    `}
`;

export const DepositAddressValue = styled(PositionDetailValue)`
  font-family: var(--font-monospaced);
`;

export const TxIdValue = styled(PositionDetailValue)`
  position: relative;
  padding-right: 30px;
  display: flex;
  align-items: center;
  word-break: break-all;
  font-family: var(--font-monospaced);
`;

export const TxExternalIdValue = styled(PositionDetailValue)`
  position: relative;
  padding-right: 30px;
  display: flex;
  align-items: center;
  font-family: var(--font-monospaced);
`;

export const IconLong = styled(SvgArrowUp)`
  margin-right: 3px;
`;
export const IconShort = styled(SvgArrowDown)`
  margin-right: 3px;
`;

export const IconCopy = styled(SvgCopy)`
  width: 20px;
  height: 20px;
  stroke: var(--accent-primary);
  fill: none;
  margin: 0 0 0 5px;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.4s;

  &:active {
    transform: scale(0.7);
  }
`;

export const WithdrawalStatusValue = styled(PositionDetailValue)`
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const DepositStatusValue = styled(PositionDetailValue)`
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const StatusLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  padding: 40px 0 30px;
`;

export const StatusLineItem = styled.div`
  width: 25%;
  min-width: 25%;
  max-width: 25%;
  position: relative;
  height: 2px;
  background: var(--border);

  &.awaiting {
    background: var(--warning);
    &:before {
      border-color: var(--warning);
      background: var(--warning);
    }
    &:after {
      border-color: var(--warning);
    }

    h4 {
      &:first-child {
        color: var(--warning);
      }
    }

    & + div {
      &:before {
        border-color: var(--warning);
      }
    }
  }

  &.failed {
    background: var(--error);
    &:before {
      border-color: var(--error);
      background: var(--error);
    }
    &:after {
      border-color: var(--error);
    }

    h4 {
      &:first-child {
        color: var(--error);
      }
    }

    & + div {
      &:before {
        border-color: var(--error);
      }
    }
  }

  &.active {
    &:before {
      border-color: var(--accent-primary);
      background: var(--accent-primary);
    }
    &:after {
      border-color: var(--accent-primary);
    }

    h4 {
      &:first-child {
        color: var(--accent-primary);
      }
    }

    & + div {
      &:before {
        border-color: var(--accent-primary);
      }
    }
  }
  &.done {
    background: var(--accent-primary);
    &:before {
      border-color: var(--accent-primary);
      background: var(--accent-primary);
    }
    h4 {
      color: var(--object-primary);
    }
  }

  &:before,
  &:after {
    position: absolute;
    width: 6px;
    height: 6px;
    border: 2px solid var(--border);
    background: var(--base);
    border-radius: 20px;
    box-sizing: content-box;
    z-index: 2;
  }
  &:before {
    content: '';
    left: 0;
    top: -4px;
  }
  &:last-child {
    &:after {
      content: '';
      right: 0;
      top: -4px;

      h4 {
        &:first-child {
          color: var(--accent-primary);
        }
      }
    }
  }
  &:nth-child(even) {
    h4 {
      &:first-child {
        top: 12px;
      }
    }
  }
`;
export const IconClock = styled(SvgClock)`
  width: 12px;
  height: 12px;
  margin: 0 0 0 5px;
  stroke: currentColor;
`;

export const IconProcessing = styled(SvgProcessing)`
  width: 10px;
  height: 10px;
  margin: 0 0 0 5px;
  stroke: currentColor;
`;

export const StatusLineTitle = styled.h4`
  margin: 0;
  font-size: var(--font-xxs);
  line-height: 12px;
  position: absolute;
  font-weight: normal;
  left: 0;
  top: -20px;
  color: var(--object-secondary);
`;

export const StatusLineFinalTitle = styled(StatusLineTitle)`
  left: auto;
  right: 0;
`;

export const ListWrapper = styled.div<{
  isMobile?: boolean;
  isAsideButtonChecked?: string;
}>`
  width: calc(100% - 30px);
  display: flex;
  // min-width: 820px;
  flex-direction: column;
  
  z-index: 0;

  ${(props) =>
    props.isMobile &&
    css`
      padding-right: 30px;
    `}

  ${(props) =>
    props.isAsideButtonChecked === 'OPEN' &&
    css`
      width: calc(100% - 250px);
    `}

  @media (min-width: 1800px) {
    width: 100%;
  }

  ${(prop) =>
    prop.isMobile &&
    css`
      @media (min-width: 1800px) {
        padding-right: 20px;
      }

      // @media (max-width: 1110px) {
      //   margin-right: 30px;
      // }
    `}
`;

export const Filters = styled.aside<{
  isMobile?: boolean;
  isAsideButtonChecked: string;
}>`
  width: 240px;
  min-width: 240px;
  grid-area: 1 / 3 / 6 / 4;
  align-self: start;
  top: 0px;
  padding: 0 10px 0 20px;
  margin-left: 10px;

  ${(prop) =>
    prop.isAsideButtonChecked === 'OPEN' &&
    css`
      // position: absolute;
      // right: 0;
      // width: 280px;
      // background: var(--background);
      // height: 100%;
      // border-left: 1px solid var(--border);
    `}

  ${(prop) =>
    prop.isMobile &&
    css`
      height: 100%;
      width: 60px;
      min-width: 60px;
      border-right: 1px solid var(--border);
      // border-left: 1px solid var(--border);
      padding: 0;
      background: var(--background);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      position: absolute;
      right: 0;
      padding: 40px 0;

      @media (min-width: 1800px) {
        position: absolute;
      }

      @media (max-width: 1110px) {
        position: absolute;
      }
    `}
`;

export const AsideButton = styled.div<{
  isMobile: boolean;
  isAsideButtonChecked: string;
}>`
  background-color: var(--base);
  border-radius: 12px;
  position: fixed;
  bottom: 40px;
  cursor: pointer;
  z-index: 100;
  transform: translateX(-2px);
  border: 1px solid var(--border);
  width: 22px;
  height: 22px;
  margin-left: -38px;
  padding-left: 0.5px;

  svg {
    transition: transform 0.3s;
    transform: rotate(270deg);
    color: var(--placeholder);
  }

  &:hover {
    svg {
      color: var(--object-primary);
    }
  }

  ${(props) =>
    props.isMobile &&
    css`
      transform: unset;
      margin: 0;
      margin-left: -58px;
      padding-left: 0;
      @media (max-width: 985px) {
        right: 36px;
      }

      @media (max-width: 1110px) {
        right: unset;
      }
      svg {
        transform: rotate(90deg);
      }
    `};

  ${(props) =>
    props.isAsideButtonChecked === 'OPEN' &&
    css`
      svg {
        transform: rotate(270deg);
      }

      @media (max-width: 1300px) {
        right: 274px;
        margin-left: 0;
      }
    `};

  ${(props) =>
    props.isAsideButtonChecked === 'CLOSED' &&
    css`
      svg {
        transform: rotate(90deg);
      }
    `};
`;

const IconCss = css`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  stroke: currentColor;
  margin: 0 10px 0 0;
`;

export const ArrowIconAside = styled(SvgArrowAside)`
  ${IconCss}
`;

export const ExportButton = styled.div<{
  disabled?: boolean;
}>`
  margin: 0 5px 5px 0;
  padding: 10px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: var(--font-xs);
  line-height: 10px;
  font-weight: 700;
  background-color: var(--border);
  color: var(--object-primary);
  user-select: none;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  height: 25px;

  &:hover {
    background-color: var(--placeholder);
  }

  background-color: var(--accent-primary);
  color: var(--dark-bg);

  &:hover {
    background-color: var(--accent-primary);
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--border);
      color: var(--object-primary);

      &:hover {
        background-color: var(--placeholder);
      }
    `}
`;

export const Filter = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  margin: 0 0 20px 0;

  &:first-child {
    margin-top: 30px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${(prop) =>
    prop.isMobile &&
    css`
      display: none;
      overflow: hidden;
    `}
`;

export const FromToInputs = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 1px;
    background: var(--border);
    margin-left: -4px;
    pointer-events: none;
  }

  input {
    max-width: 95px;
    min-width: 95px;
  }
`;

export const FiltersIcon = styled(SvgFiltersIcon)`
  path {
    transition: all 0.3s;
  }

  &:hover path {
    stroke: var(--object-primary);
  }
`;

export const FiltersData = styled.div<{ isMobile: boolean }>`
  position: relative;

  transition: all 0.3s;
  cursor: pointer;

  ${(prop) =>
    prop.isMobile &&
    css`
      position: fixed;

      @media (max-width: 985px) {
        right: 36px;
      }

      @media (max-width: 1110px) {
        right: unset;
      }
    `}

  @media (min-width: 1800px) {
    position: fixed;
  }
`;

export const FilterDivider = styled.div`
  width: 100%;
  height: 30px;

  @media (min-height: 1110px) {
    display: none;
  }
`;

export const FiltersCounter = styled.div`
  min-width: 12px;
  height: 12px;
  background-color: var(--accent-primary);
  border-radius: 12px;
  font-weight: 400;
  font-size: var(--font-xxxs);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: -1px;
  position: absolute;
  right: 0;
  top: -5px;
  right: -5px;
  padding: 3px;
`;

export const StatusButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StatusStyleCss = css`
  display: flex;
  line-height: 10px;
  font-size: var(--font-xxxs);
  text-transform: capitalize;
  font-weight: 400;

  &:disabled {
    background: var(--border);
  }

  &:last-child {
    margin: 0;
  }
`;

export const IconApproved = styled(SvgApproved)`
  width: 10px;
  height: 10px;
  margin: 0 0 0 5px;
  stroke: currentColor;
`;

export const IconRejected = styled(SvgRejected)`
  width: 10px;
  height: 10px;
  margin: 0 0 0 5px;
  stroke: currentColor;
`;

export const ArrowIcon = styled(SvgArrow)<{ isopen?: string }>`
  width: 16px;
  height: 16px;
  transition: all 0.2s;

  cursor: pointer;
  stroke: currentColor;
  transition: 0.4s;
  stroke: var(--placeholder);

  ${(props) =>
    props.isopen &&
    css`
      transform: rotate(180deg);
    `};
`;
export const ConfirmationsIcon = styled(SvgConfirmations)`
  margin-right: 9px;

  path {
    stroke: var(--placeholder);
  }
`;

const IconVoteCss = css`
  width: 20px;
  height: 20px;
  stroke: currentColor;
  margin: 0 5px 0 0;
`;
export const VoteUpIcon = styled(SvgVote)`
  ${IconVoteCss}
`;
export const VoteDownIcon = styled(SvgVote)`
  ${IconVoteCss}
  transform: rotate(180deg);
`;
export const StatusBarLineSubItems = styled.div`
  width: 100%;
  padding: 4px 0;
`;
export const StatusBarLineVotes = styled.div`
  width: 100%;
  padding: 4px 0;
  display: flex;
  flex-direction: column-reverse;
`;
export const ConfirmationsVote = styled.span`
  display: flex;
  align-items: center;
  font-weight: 700;
`;
export const IconConfirmationsVote = styled(SvgVote)`
  width: 10px;
  height: 10px;
  stroke: currentColor;
  margin: 0 5px 0 0;
`;
export const ConfirmationsVoteEmail = styled.span`
  margin: 0 0 0 5px;
  color: var(--object-secondary);
  font-weight: 400;
`;
export const StatusBarLineSubItemError = styled.div`
  width: 100%;
  color: var(--warning);
`;
export const StatusBarLineSubItem = styled.div<{
  dir?: string;
  complete?: boolean;
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-xxs);
  line-height: 14px;
  color: var(--object-secondary);
  padding: 5px 0;
  position: relative;

  &.awaiting {
    color: var(--object-primary);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      margin-top: -3px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      border: 2px solid var(--warning);
    }
  }

  &.inactive {
    color: var(--object-secondary);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      margin-top: -3px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: var(--border);
      border: 2px solid var(--border);
    }
  }

  &.failed {
    color: var(--error);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      margin-top: -3px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: var(--error);
      border-color: var(--success);
    }
  }

  &.active {
    color: var(--object-primary);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      margin-top: -3px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      border: 2px solid var(--success);
    }
  }

  &.completed {
    color: var(--success);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      margin-top: -3px;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: var(--success);
      border-color: var(--success);
    }
  }

  ${(prop) =>
    prop.dir === 'up' &&
    css`
      ${ConfirmationsVote} {
        color: var(--success);
      }
    `}
  ${(prop) =>
    prop.dir === 'down' &&
    css`
      ${ConfirmationsVote} {
        color: var(--error);
      }
      ${IconConfirmationsVote} {
        transform: rotate(180deg);
      }
    `}
`;

export const ApprovedIcon = styled(SvgApprovedStatus)``;
export const ClockIcon = styled(SvgClock)``;
export const RejectedIcon = styled(SvgRejectedStatus)``;

export const StatusApproveButton = styled.button`
  ${StatusStyleCss}
  color: var(--success);
`;

export const StatusRejectButton = styled.button`
  ${StatusStyleCss}
  color: var(--error);
`;

export const StatusApproved = styled.div`
  ${StatusStyleCss}
  color: var(--success);
`;

export const StatusReject = styled.div`
  ${StatusStyleCss}
  color: var(--error);
  border-color: var(--error);
`;

export const PositionExchangeRow = styled.div`
  margin-top: 22px;
`;
export const PositionExchangeLogo = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  max-width: 15px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  border-radius: 50%;
  margin: 0 5px 0 0;
`;
export const PositionExchangeName = styled.div`
  margin-left: 18px;
  display: flex;
  align-items: center;
  color: var(--object-primary);
  font-family: Lato;
  font-weight: bold;
  font-size: var(--font-sm);
`;

export const PositionAccountRow = styled.div``;
export const PositionAccountExchangeLogo = styled.div`
  width: 15px;
  height: 10px;
  min-width: 10px;
  max-width: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  border-radius: 50%;
  margin: 0 5px 0 0px;
`;
export const PositionAccountName = styled.div`
  margin-left: 18px;
  margin-top: 11px;
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  color: var(--object-primary);
  font-family: var(--font-main);
  font-weight: bold;
  font-size: var(--font-sm);
  cursor: pointer;
`;

export const FilterViewLabel = styled.div`
  text-transform: uppercase;
  color: var(--object-primary);
  font-size: var(--font-sm);
  font-family: var(--font-main);
  font-weight: bold;
`;

export const ExchangeCell = styled.div<{ active: boolean }>`
  min-width: 100px;
  max-width: 100px;
  position: sticky;
  left: 0px;
  z-index: 2;
  // background: var(--base);
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--accent-primary);
      font-weight: 700;
      &:after {
        background: var(--accent-primary);
      }
    `};
`;

export const CurrencySelectCell = styled(ExchangeCell)`
  // background: var(--base) !important;
  overflow: visible;
  z-index: 99;
`;

export const CurrencySelectWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CurrencySelectItem = styled.div<{
  active: boolean;
  disabled: boolean;
}>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  cursor: pointer;
  background-color: var(--border);

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }

  ${(props) =>
    props.active &&
    css`
      background: var(--accent-primary);
      color: var(--base);
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: var(--border);
      color: var(--base);
      pointer-events: none;
    `};
`;
