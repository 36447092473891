import styled, { css, keyframes } from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';

import { ReactComponent as SvgTransfer } from 'shared/assets/icons/icon-new-transfer.svg';
import { ReactComponent as SvgTransactions } from 'shared/assets/icons/icon-transactions.svg';
import { ReactComponent as SvgWhitelists } from 'shared/assets/icons/icon-whitelists.svg';
import { ReactComponent as SvgOverview } from 'shared/assets/icons/icon-overview.svg';
import { ReactComponent as SvgMultisig } from 'shared/assets/icons/icon-wallet.svg';
import { ReactComponent as SvgHelp } from 'shared/assets/icons/icon-help.svg';
import { ReactComponent as SvgUser } from 'shared/assets/icons/icon-user.svg';
import { ReactComponent as SvgLogout } from 'shared/assets/icons/icon-logout.svg';
import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-dropdown-small.svg';
import { ReactComponent as SvgLogo } from 'shared/assets/icons/views/logo-multik.svg';
import { ReactComponent as SvgMobileLogo } from 'shared/assets/icons/views/logo-mobile-multik.svg';
import { ReactComponent as SvgArrowAside } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgUpDown } from 'shared/assets/icons/icon-up-down.svg';
import { ReactComponent as SvgRefresh } from 'shared/assets/icons/icon-refresh.svg';
import { ReactComponent as SvgSettings } from 'shared/assets/icons/icon-settings-bold.svg';

const appearAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const AsideWrap = styled.aside<{ isMobile: boolean }>`
  width: 170px;
  max-width: 170px;
  min-width: 170px;
  padding: 30px 30px 40px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 999;
  margin-left: 15px;
  transition: all 0.3s;

  &.hidden {
    height: 60px;
  }

  ${(props) =>
    props.isMobile &&
    css`
      width: 60px;
      transition: unset;
      min-width: 60px;
      padding: 30px 0 40px 0;
      align-items: center;
      margin-left: 0;
    `};
`;

export const LogoWrap = styled(NavLink)<{ ismobile?: string }>`
  display: flex;
  margin: 0 0 40px 0;
  align-items: center;
  user-select: none;

  span {
    font-weight: 700;
    font-size: var(--font-xxl);
    line-height: 18px;
  }

  ${(props) =>
    props.ismobile === 'true' &&
    css`
      justify-content: flex-start;
    `};
`;

export const Logo = styled(SvgLogo)`
  height: 38px;
  opacity: 0;
  animation: ${appearAnimation} 0.3s forwards;
  animation-delay: 0.1s;
`;

export const MobileLogo = styled(SvgMobileLogo)<{ dark: boolean }>`
  opacity: 0;
  animation: ${appearAnimation} 0.3s forwards;
  animation-delay: 0.1s;

  ${(props) =>
    props.dark &&
    css`
      path {
        fill: #fff;
      }
    `};
`;

export const Top = styled.div<{ isMobile: boolean }>`
  width: 150px;
  position: fixed;

  ${(props) =>
    props.isMobile &&
    css`
      width: 20px;
    `};
`;

export const MainMenu = styled.nav`
  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    height: 0;
  }
`;

export const UserMenu = styled.nav<{ isMobile: boolean }>`
  position: fixed;

  bottom: 40px;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  ${(props) =>
    props.isMobile &&
    css`
      width: 20px;
    `};
`;

export const MenuItemStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: var(--font-md);
  font-weight: 700;
  line-height: 17px;
  margin: 0;
  color: var(--object-secondary);
  transition: all 0.3s;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const MenuItemWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  flex-wrap: wrap;

  &:last-child {
    padding: 10px 0 0 0;
  }
`;
export const MenuItem = styled.span`
  ${MenuItemStyle}
`;

export const UserFullName = styled.span`
  overflow-wrap: break-word;
  width: 130px;
`;
const IconCss = css`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  stroke: currentColor;
  margin: 0 10px 0 0;
`;
export const DropdownMenu = styled(motion.div)<{
  isshown?: string;
  ismobile?: string;
}>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 2px 0 0 30px;

  ${(props) =>
    props.ismobile === 'true' &&
    css`
      width: 100px;
      height: 0;
      opacity: 0;
      left: 20px;
      overflow: hidden;
      top: 277px;
      background: var(--base);
      border-radius: 5px;
      margin-top: 10px;
      padding: 0;

      ${props.isshown === 'true' &&
      css`
        overflow: visible;
        opacity: 1;
        height: 100px;
        padding: 10px 10px;
      `};
    `};
`;
export const DropdownMenuItem = styled.div<{
  activeMenu?: boolean;
  isMobile: boolean;
}>`
  width: 100%;
  font-size: var(--font-md);
  line-height: 17px;
  font-weight: 100;
  color: var(--object-secondary);
  margin: 5px 0;
  cursor: pointer;

  ${(props) =>
    props.activeMenu &&
    css`
      color: var(--object-primary);
    `};

  ${(props) =>
    props.isMobile &&
    css`
      margin: 0;
      display: flex;
      align-items: center;
    `};
`;

export const IconSettings = styled(SvgSettings)`
  ${IconCss}
`;

export const IconTransfer = styled(SvgTransfer)`
  ${IconCss}
`;

export const IconTransactions = styled(SvgTransactions)`
  ${IconCss}
`;

export const IconWhitelists = styled(SvgWhitelists)`
  ${IconCss}
`;

export const IconPositions = styled(SvgUpDown)`
  ${IconCss}
`;

export const IconOrders = styled(SvgRefresh)`
  ${IconCss}
`;

export const IconOverview = styled(SvgOverview)`
  ${IconCss}
`;
export const IconMultisig = styled(SvgMultisig)`
  ${IconCss}
`;
export const IconHelp = styled(SvgHelp)`
  ${IconCss}
`;
export const IconUser = styled(SvgUser)`
  ${IconCss}
`;
export const IconLogout = styled(SvgLogout)`
  ${IconCss}
`;
export const ArrowIcon = styled(SvgArrow)<{ ismobile?: string }>`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -5px;
  transition: all 0.2s;

  opacity: 0;
  animation: ${appearAnimation} 0.3s forwards;
  animation-delay: 0.1s;

  ${(props) =>
    props.ismobile === 'true' &&
    css`
      display: none;
    `}
`;
export const MenuItemLinkStyle = css`
  ${MenuItemStyle}
  cursor: pointer;

  svg path {
    transition: all 0.1s;
  }

  &.active {
    color: var(--object-primary);

    .dropdownarrow {
      transform: rotate(180deg);
    }
    svg {
      stroke: var(--accent-secondary);
    }

    svg path {
      transition: all 0.3s;
      stroke: var(--accent-secondary);
    }
  }

  &:hover {
    color: var(--object-primary);

    svg path {
      transition: all 0.3s;
      stroke: var(--object-primary);
    }
  }
`;
export const MenuItemLink = styled(Link)<{ ismobile?: string }>`
  ${MenuItemLinkStyle}

  span {
    opacity: 0;
    animation: ${appearAnimation} 0.3s forwards;
    animation-delay: 0.1s;
  }

  ${(props) =>
    props.ismobile === 'true' &&
    css`
      justify-content: flex-start;

      span {
        display: none;
      }

      svg {
        width: 20px;
        height: 20px;
        max-width: unset;
      }
    `}
`;
export const LogoutButton = styled.div<{ isMobile: boolean }>`
  ${MenuItemLinkStyle}

  svg, span {
    opacity: 0;
    animation: ${appearAnimation} 0.3s forwards;
    animation-delay: 0.1s;
  }

  ${(props) =>
    props.isMobile &&
    css`
      span {
        display: none;
      }
    `}
`;

export const ArrowIconAside = styled(SvgArrowAside)`
  ${IconCss}
`;

export const AsideButton = styled.div<{ isMobile: boolean }>`
  ${MenuItemLinkStyle}
  background-color: var(--base);
  border-radius: 12px;
  max-width: 22px;
  right: -160px;
  transition: all 0.2s;
  border: 1px solid var(--border);
  height: 22px;
  width: 22px;

  svg {
    transition: all 0.2s;
    transform: rotate(90deg);
    color: var(--placeholder);
  }

  &:hover {
    svg {
      color: var(--object-primary);
    }
  }

  ${(props) =>
    props.isMobile &&
    css`
      right: -30px;
      transition: unset;
      padding-left: 1px;
      svg {
        transform: rotate(270deg);
      }
    `};
`;
