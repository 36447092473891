import styled, { css } from 'styled-components/macro';
import { Field } from 'formik';

export const Label = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;
export const Text = styled.span`
  position: relative;
`;
export const Input = styled(Field)`
  display: none;

  & + ${Text} {
    position: relative;
    padding: 0 0 0 25px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-size: var(--font-sm);

    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border: solid 1px #a3b1c6;
      border-radius: 2px;
      left: 0;
      top: 4px;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 4px;
      border: solid 1px rgba(255, 255, 255, 0);
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 1px;
      transform: rotate(-45deg) translate(0px, 6px);
      transform-origin: center;
    }
  }
  &:checked {
    & + ${Text} {
      &:after {
        border-color: var(--accent-primary);
        clip-path: polygon(
          0% 0%,
          60% 0%,
          60% 50%,
          100% 50%,
          100% 100%,
          0% 100%
        );
      }
      &:before {
        border-left: 1px solid var(--accent-primary);
        border-bottom: 1px solid var(--accent-primary);
        transform: rotate(-45deg) translate(0px, 7px);
      }
    }
  }
`;
export const Wrap = styled.div<{ error?: boolean; pt?: number; pb?: number }>`
  padding: 0;

  ${(prop) =>
    prop.error &&
    css`
      color: var(--error);
      ${Text} {
        color: var(--error);
      }
    `}
  ${(prop) =>
    prop.pt &&
    css`
      padding-top: ${prop.pt}px;
    `}
    ${(prop) =>
    prop.pb &&
    css`
      padding-bottom: ${prop.pt}px;
    `}
`;
