import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as SvgCheck } from 'shared/assets/icons/icon-check-success.svg';
import { ReactComponent as SvgUncheck } from 'shared/assets/icons/icon-uncheck-failed.svg';
import { ReactComponent as SvgChevron } from 'shared/assets/icons/icon-chevron-down.svg';
import { requestPatch } from 'shared/axios';
import { CodeInput } from 'shared/components/OtpModal/shared';
import AppContext from 'shared/contexts/AppContext';
import { parseError } from 'shared/helpers/errors';
import { notify } from 'shared/components/Notification/notify';
import { capitalize } from 'shared/helpers/format';

export const IconChevron = styled(SvgChevron)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin: 0 5px 0 0;
  stroke: var(--placeholder);
`;

export const IconCheck = styled(SvgCheck)`
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin: 0 5px 0 0;
  stroke: currentColor;
`;

export const IconUncheck = styled(SvgUncheck)`
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin: 0 5px 0 0;
  stroke: currentColor;
`;

const Wrap = styled.div`
  .hoverable-option:hover {
    background: var(--background);
  }
`;

export const ManualResolve = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const [otpErrorText, setOtpErrorText] = useState<string | null>(null);
  const { appSettings, openModal } = useContext(AppContext);
  const otpRef = useRef(null);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const onOtpReset = () => {
    setOtp('');
  };
  const isOtpRequired = appSettings.is_hotp_enabled;

  const onOtpChange = (value: string) => {
    setOtpErrorText(null);
    setOtp(value);
  };
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');
  const { mutateAsync: resolveManually, isLoading } = useMutation({
    mutationKey: ['manual-resolve'],
    mutationFn: async () => {
      const response = await requestPatch(
        `/transactions/${id}/resolve/`,
        {
          status,
          resolve_comment: comment,
        },
        {
          headers: {
            ...(otp && { 'X-AUTH-HOTP': otp }),
          },
        },
      );
      return response.data;
    },
  });
  const onChangeComment = (e: any) => {
    setError('');
    setOtpErrorText('');
    if (e.target?.value.length > 255) {
      setComment(e.target?.value.substring(0, 255));
    } else {
      setComment(e.target?.value);
    }
  };

  const onSubmit = async () => {
    try {
      await resolveManually();
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['transactions_history'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['transactions_queue'],
        }),
      ]);
      notify({
        type: 'success',
        header: `You resolved a transaction with the "${capitalize(
          status.toLowerCase(),
        )}" status`,
      });
      openModal(null);
    } catch (error) {
      const errorText = parseError(error, appSettings);
      if (errorText.includes('OTP')) {
        setOtpErrorText(errorText);
      } else {
        setError(errorText);
      }
    }
  };

  const isDisabled =
    !status || !comment || isLoading || (isOtpRequired && otp?.length !== 6);

  return (
    <Wrap>
      Please choose the status of the transaction on the exchanges:
      <br />
      <br />
      <ul style={{ listStyle: 'outside', marginLeft: '24px' }}>
        <li style={{ marginBottom: '12px' }}>
          If the withdrawal from the sending exchange failed, select the status
          'Failed'.
        </li>
        <li style={{ marginBottom: '12px' }}>
          If the funds were sent but not received, select 'Failed' and contact
          the support team of the receiving exchange.
        </li>
        <li style={{ marginBottom: '12px' }}>
          If the exchange has successfully received the deposit, select the
          status 'Completed'."
        </li>{' '}
      </ul>
      <Listbox value={status} onChange={setStatus}>
        {({ open }) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <Label
              style={{
                color: 'var(--object-secondary)',
                fontSize: 'var(--font-xxxs)',
                marginBottom: '8px',
                marginTop: '16px',
              }}
            >
              STATUS
            </Label>
            <ListboxButton
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid var(--border)',
                borderRadius: '5px',
                padding: '10px',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {status &&
                  (status === 'COMPLETED' ? <IconCheck /> : <IconUncheck />)}
                {status ? (
                  <div
                    style={{
                      marginLeft: '2px',
                      textTransform: 'capitalize',
                      color:
                        status === 'COMPLETED'
                          ? 'var(--success)'
                          : 'var(--error)',
                    }}
                  >
                    {status.toLowerCase()}
                  </div>
                ) : (
                  <div style={{ color: 'var(--placeholder)' }}>
                    Select status
                  </div>
                )}
              </div>
              <div>
                <IconChevron
                  style={{
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </div>
            </ListboxButton>
            <ListboxOptions
              style={{
                width: '100%',
                background: 'var(--base)',
                position: 'absolute',
                cursor: 'pointer',
                top: '100%',
                border: '1px solid var(--border)',
                borderRadius: '5px',
              }}
            >
              <ListboxOption
                className="hoverable-option"
                style={{
                  padding: '10px 10px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'var(--success)',
                }}
                key={'COMPLETED'}
                value={'COMPLETED'}
              >
                <IconCheck style={{ marginRight: '6px' }} />
                Completed
              </ListboxOption>
              <ListboxOption
                className="hoverable-option"
                style={{
                  padding: '10px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'var(--error)',
                }}
                key={'FAILED'}
                value={'FAILED'}
              >
                <IconUncheck
                  style={{
                    stroke: 'var(--error)',
                    marginRight: '6px',
                  }}
                />
                Failed
              </ListboxOption>
            </ListboxOptions>
          </div>
        )}
      </Listbox>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'var(--object-secondary)',
            fontSize: 'var(--font-xxxs)',
            marginBottom: '8px',
            marginTop: '16px',
          }}
        >
          <div>RESOLVE COMMENT</div>{' '}
          <div style={{ fontSize: '7px', marginLeft: '4px' }}>
            ({comment.length}/255)
          </div>
        </div>
        <textarea
          style={{
            border: '1px solid var(--border)',
            background: 'var(--base)',
            borderRadius: '5px',
            resize: 'none',
            width: '100%',
            outline: 'none',
            padding: '10px',
          }}
          name="comment"
          id=""
          value={comment}
          onChange={onChangeComment}
        ></textarea>
      </div>
      <div style={{ width: '270px', marginTop: '13px' }}>
        <CodeInput
          bold={false}
          responsive={false}
          title="Multik Authenticator Code"
          ref={otpRef}
          value={otp}
          autoFocus={false}
          required={isOtpRequired}
          onChange={onOtpChange}
          onReset={onOtpReset}
          errorText={otpErrorText}
          showError={true}
        />
      </div>
      {error && (
        <div
          style={{ color: 'var(--error', fontSize: 'var(--font-xxs)', marginTop: '-16px' }}
        >
          {error}
        </div>
      )}
      <div
        style={{
          width: '100%',
          height: '1px',
          background: 'var(--border)',
          margin: '20px 0',
        }}
      ></div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div></div>
        <div
          onClick={onSubmit}
          style={{
            cursor: 'pointer',
            borderRadius: '30px',
            color: 'var(--dark-bg)',
            fontWeight: 'bold',
            padding: '13px 20px',
            textTransform: 'uppercase',
            background: isDisabled
              ? 'var(--placeholder)'
              : 'var(--accent-primary)',
          }}
        >
          Manually resolve
        </div>
      </div>
    </Wrap>
  );
};
