import styled, { css } from 'styled-components/macro';

export const FromToInputs = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  font-family: var(--font-monospaced);

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 1px;
    background: var(--border);
    margin-left: -4px;
    pointer-events: none;
  }

  input {
    max-width: 95px;
    min-width: 95px;
  }
`;
export const Input = styled.input`
  padding: 5px 8px;
  background: none;
  border: 1px solid var(--border);
  font-size: var(--font-sm);
  border-radius: 5px;
  line-height: 20px;
  width: 100%;

  &:focus-visible,
    &:focus {
      outline: var(--accent-primary) solid 1px;
    }
`;
export const Label = styled.label`
  font-size: var(--font-sm);
  // padding: 0 8px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--object-primary);

  span {
    color: var(--object-secondary);
    font-weight: 100;
  }
`;

export const LabelWrap = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
`;
export const FilterClearButton = styled.button`
  font-size: var(--font-sm);
  background: none;
  border: none;
  padding: 0;
  color: var(--accent-primary);
`;

export const FilterContent = styled.div<{
  noPadding?: boolean;
}>`

  & label {
    cursor: pointer;
    margin-bottom: 10px;
  }

  ${(prop) =>
    prop.noPadding &&
    css`
      padding: 0;
    `}
`;

export const ExchangeIcon = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  background-size: 20px;
`;

export const Checkbox = styled.input`
  display: none;

  & + span {
    position: relative;
    padding: 0 0 0 25px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-size: var(--font-sm);

    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border: solid 2px var(--placeholder);
      border-radius: 2px;
      left: 0;
      top: 3px;
      transition: 0.2s;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 4px;
      border: solid 1px rgba(255, 255, 255, 0);
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 1px;
      transform: rotate(-45deg) translate(0px, 6px);
      transition: 0.2s;
      transform-origin: center;
    }
  }
  &:checked {
    & + span {
      &:after {
        border: 2px solid var(--accent-primary);
        // border-color: var(--accent-primary);
        clip-path: polygon(
          0% 0%,
          60% 0%,
          60% 50%,
          100% 50%,
          100% 100%,
          0% 100%
        );
      }
      &:before {
        border-left: 2px solid var(--accent-primary);
        border-bottom: 2px solid var(--accent-primary);
        transform: scale(1.2) rotate(-45deg) translate(1px, 6px);
      }
    }
  }
`;
export const CheckboxNative = styled.input`
  display: none;

  & + span {
    position: relative;
    padding: 0 0 0 25px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-size: var(--font-sm);

    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border: solid 1px #a3b1c6;
      border-radius: 2px;
      left: 0;
      top: 4px;
      transition: 0.2s;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 4px;
      border: solid 1px rgba(255, 255, 255, 0);
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 1px;
      transform: rotate(-45deg) translate(0px, 6px);
      transition: 0.2s;
      transform-origin: center;
    }
  }
  &:checked {
    & + span {
      &:after {
        border-color: var(--accent-primary);
        clip-path: polygon(
          0% 0%,
          60% 0%,
          60% 50%,
          100% 50%,
          100% 100%,
          0% 100%
        );
      }
      &:before {
        border-left: 1px solid var(--accent-primary);
        border-bottom: 1px solid var(--accent-primary);
        transform: rotate(-45deg) translate(0px, 7px);
      }
    }
  }
`;
