import styled, { css } from 'styled-components/macro';
import { ReactComponent as WarningSvg } from 'shared/assets/icons/icon-warning.svg';

export const TransferList = styled.div`
  position: relative;
  z-index: 1;
  padding: 30px 25px;
  max-height: calc(100vh - 120px);
  min-height: 0;
  width: 50%;
  overflow: auto;
`;

export const TransferListWrap = styled.div<{ paddingBottom?: string }>`
  padding: 0;
  ${(props) =>
  props.paddingBottom &&
  css`
      padding-bottom: ${props.paddingBottom};
    `};
`;

export const WarningLogo = styled(WarningSvg)`
  width: 11px;
  height: 11px;
  position: relative;
  top: 4px;
  left: 5px;
  outline: none;
  cursor: pointer;
`;

export const Row = styled.section`
  margin: 0 0 15px 0;
  position: relative;
  z-index: 1;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ExchangeTop = styled.header`
  display: flex;
  width: 100%;
  margin: 0 0 5px 0;
  padding: 5px;
`;

export const ExchangeName = styled.div`
  display: block;
  text-transform: uppercase;
  font-size: var(--font-sm);
  font-weight: 700;
  line-height: 20px;
  color: var(--object-primary);
  margin: 0 0 1px 0;
  cursor: pointer;
  user-select: none;
`;

export const NotAvalable = styled.div`
  padding: 20px 30px;
  border: 2px solid var(--border);
  color: var(--object-secondary);
  border-radius: 10px;
  line-height: 14px;
  font-size: var(--font-sm);

  span {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 30px 0 0;
  }
`;
