import { FC, forwardRef, useRef } from 'react';
import ReactCodeInput from 'react-verification-input';
import { getExchangeLogoUrl } from 'shared/constants/exchanges';
import { capitalize, convertExpToDecimal } from 'shared/helpers/format';

import * as S from '../Modal/styled';

export const digitsNumber = 6;

interface CodeInputProps {
  bold?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  onChange: any;
  onReset: any;
  errorText: string | null;
  title: string;
  value: string;
  noMargin?: boolean;
  showError?: boolean;
  responsive?: boolean;
}

export const CodeInput = forwardRef(
  (
    {
      bold = true,
      required = true,
      onChange,
      onReset,
      errorText,
      title,
      autoFocus,
      value,
      noMargin,
      showError = false,
      responsive = false,
    }: CodeInputProps,
    ref,
  ) => {
    if (!required) return null;

    return (
      <>
        <S.CodeHeader>
          <S.OtpText bold={bold} responsive={responsive}>
            {title}
          </S.OtpText>
          {value && (
            <S.OtpReset responsive={responsive} onClick={onReset}>
              Reset
            </S.OtpReset>
          )}
        </S.CodeHeader>
        <S.OtpCodeWrap noMargin={noMargin}>
          {/* @ts-ignore */}
          <ReactCodeInput
            classNames={{
              container: 'code-input-container',
              character: errorText
                ? 'code-input-character-error'
                : 'code-input-character',
              characterInactive: 'code-input-character--inactive',
              characterSelected: 'code-input-character--selected',
              characterFilled: 'code-input-character--filled',
            }}
            autoFocus={autoFocus}
            value={value}
            validChars="1234567890"
            length={digitsNumber}
            placeholder=""
            onChange={onChange}
          />

          {errorText && showError && (
            <div
              style={{
                color: 'var(--error',
                fontSize: 'var(--font-xxs)',
                marginTop: '4px',
              }}
            >
              {errorText}
            </div>
          )}
        </S.OtpCodeWrap>
      </>
    );
  },
);

interface SubmitButtonProps {
  isSubmit: boolean;
  errorText: string | null;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  isSubmit,
  errorText,
}) => {
  if (isSubmit && !errorText) return <>Submitting…</>;

  return (
    <>
      Submit <S.IconArrow />
    </>
  );
};

interface OtpProposalDetailsProps {
  amount: string;
  currency: string;
  fromAccount: any;
  toAccount: any;
  isFiat: boolean;
  networkInfo: any;
  walletFromType: string;
  walletToType: string;
}

export const OtpProposalDetails: FC<OtpProposalDetailsProps> = ({
  amount,
  currency,
  fromAccount,
  walletFromType,
  walletToType,
  toAccount,
  isFiat,
  networkInfo,
}) => {
  const fromLogo = getExchangeLogoUrl(fromAccount?.exchange);
  const toLogo = getExchangeLogoUrl(isFiat ? 'FIAT' : toAccount?.exchange);
  const fromAccountName = fromAccount?.name
    ? fromAccount.name
    : 'Unavailable account';
  const toAccountName = toAccount?.name
    ? toAccount?.name
    : isFiat
    ? networkInfo
    : 'Unavailable account';
  const fromAccountExchange = fromAccount?.exchange
    ? fromAccount?.exchange
    : '';
  const toAccountExchange = toAccount?.exchange
    ? toAccount?.exchange
    : isFiat
    ? 'FIAT'
    : '';
  console.log(fromAccount, toAccount);

  return (
    <S.OtpProposalDetailsWrap>
      <S.OtpAmount>
        {convertExpToDecimal(Number(amount))} {currency}
      </S.OtpAmount>
      <S.OtpAccount>
        <S.OtpAccountName unavailable={!fromAccount?.name}>
          {fromAccountName}
        </S.OtpAccountName>
        {walletFromType && (
          <S.OtpAccountName
            unavailable={!fromAccount?.name}
            style={{
              color: 'var(--object-secondary)',
            }}
          >
            {capitalize(walletFromType)}
          </S.OtpAccountName>
        )}
        <S.OtpAccountExchange>
          {fromLogo && (
            <S.OtpAccountExchangeIcon
              style={{
                backgroundImage: `url(${fromLogo})`,
              }}
            />
          )}
          {fromAccountExchange}
        </S.OtpAccountExchange>
      </S.OtpAccount>
      <S.OtpDirectionIcon />
      <S.OtpAccount>
        <S.OtpAccountName unavailable={!toAccount?.name && !isFiat}>
          {toAccountName}
        </S.OtpAccountName>
        {walletToType && (
          <S.OtpAccountName
            unavailable={!toAccount?.name && !isFiat}
            style={{
              color: 'var(--object-secondary)',
            }}
          >
            {capitalize(walletToType)}
          </S.OtpAccountName>
        )}
        <S.OtpAccountExchange>
          {toLogo && (
            <S.OtpAccountExchangeIcon
              style={{
                backgroundImage: `url(${toLogo})`,
              }}
            />
          )}
          {toAccountExchange}
        </S.OtpAccountExchange>
      </S.OtpAccount>
    </S.OtpProposalDetailsWrap>
  );
};
