import styled, { css, keyframes, ThemeProps } from 'styled-components/macro';
import { motion } from 'framer-motion';
import TransferColumn from './components/TransferColumn/TransferColumn';
import { ReactComponent as SvgCancel } from 'shared/assets/icons/icon-cancel.svg';
import { ReactComponent as SvgArrowRight } from 'shared/assets/icons/icon-arrow-right.svg';
import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-transfer.svg';
import { ReactComponent as SvgX } from 'shared/assets/icons/icon-x.svg';
import { ReactComponent as SvgArrowAside } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgArrowRounded } from 'shared/assets/icons/icon-chevron-rounded.svg';
import { ReactComponent as SvgFiltersIcon } from 'shared/assets/icons/filters-icon.svg';
import { ReactComponent as SvgSearch } from 'shared/assets/icons/icon-search.svg';
import { ReactComponent as SvgClose } from 'shared/assets/icons/icon-modal-close.svg';

export const Wrap = styled(motion.div)<{
  ismobile?: string;
  isasidemobiletype?: string;
}>`
  width: 100%;
  background: var(--base);
  position: relative;
  border-left: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  min-width: 830px;

  @media (max-width: 1020px) {
    padding-right: 280px;
    min-width: 1110px;
  }

  ${(prop) =>
    prop.ismobile === 'true' &&
    css`
      @media (max-width: 1020px) {
        padding-right: 60px;
        min-width: 890px;
      }

      @media (min-width: 950px) and (max-width: 1020px) {
        padding-right: ${prop.isasidemobiletype === 'true' ? '10px' : '60px'};
      }
    `}
`;

export const TransferWrap = styled(motion.div)<{ ismobile?: string }>`
  width: 100%;
  display: flex;
  height: 100%;
  // grid-template-columns: minmax(400px, 1fr) 280px;

  ${(prop) =>
    prop.ismobile === 'true' &&
    css`
      // grid-template-columns: minmax(400px, 1fr) 60px;
    `}
`;

export const Filters = styled.aside<{
  isMobile?: boolean;
  isAsideButtonChecked: string;
}>`
  width: 280px;
  align-self: start;
  overflow: auto;
  top: 0px;
  position: relative;
  max-height: 100%;
  padding: 0px 40px 40px 30px;
  min-width: 282px;

  @media (max-height: 1058px) {
    padding-right: 20px;
  }

  ${(prop) =>
    prop.isAsideButtonChecked === 'OPEN' &&
    css`
      position: sticky;
      right: 0;
      background: var(--background);
      border-left: 1px solid var(--border);
      height: 100%;
      width: 280px;
      padding: 0px 40px 40px 30px;
    `}

  ${(prop) =>
    prop.isMobile &&
    css`
      height: 100%;
      width: 60px;
      min-width: 60px;
      border-right: 1px solid var(--border);
      border-left: 1px solid var(--border);
      padding: 0;
      background: var(--background);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      right: 0;
      overflow: visible;

      @media (max-height: 1058px) {
        padding-right: 0;
      }
    `}

  @media (max-width: 1020px) {
    position: fixed;
  }
`;

export const Filter = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  margin: 0 0 30px 0;
  transition: all 0.3s;

  &:first-child {
    margin-top: 30px;
  }

  ${(prop) =>
    prop.isMobile &&
    css`
      display: none;
      overflow: hidden;
    `}
`;

export const FilterHr = styled.div`
  width: 100%;
  margin: 0 0 30px 0;
  height: 1px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background: var(--border);
  }
`;

export const FiltersIcon = styled(SvgFiltersIcon)`
  path {
    transition: all 0.3s;
  }

  &:hover path {
    stroke: var(--object-primary);
  }
`;

export const FiltersData = styled.div`
  position: sticky;
  top: 40px;

  transition: all 0.3s;

  cursor: pointer;
`;

export const FilterDivider = styled.div`
  width: 100%;
  height: 30px;

  @media (min-height: 1110px) {
    display: none;
  }
`;

export const FiltersCounter = styled.div`
  min-width: 12px;
  height: 12px;
  background-color: var(--accent-primary);
  border-radius: 12px;
  font-weight: 400;
  font-size: var(--font-xxxs);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: -1px;
  position: absolute;
  right: 0;
  top: -5px;
  right: -5px;
  padding: 3px;
`;

export const AsideButton = styled.div<{
  isMobile: boolean;
  isAsideButtonChecked: string;
}>`
  background-color: var(--base);
  border-radius: 12px;
  position: fixed;
  bottom: 40px;
  cursor: pointer;
  z-index: 100;
  margin-top: 20px;
  border: 1px solid var(--border);
  width: 22px;
  height: 22px;
  margin-left: -40px;
  padding-left: 1px;

  svg {
    transition: all 0.3s;
    transform: rotate(270deg);
    color: var(--placeholder);
  }

  &:hover {
    svg {
      color: var(--object-primary);
    }
  }

  ${(props) =>
    props.isMobile &&
    css`
      position: sticky;
      margin: 0;
      margin-left: -58px;
      transform: unset;
      padding-left: 0;
      svg {
        transform: rotate(90deg);
      }
    `};

  ${(props) =>
    props.isAsideButtonChecked === 'OPEN' &&
    css`
      position: fixed;
      svg {
        transform: rotate(270deg);
      }

      @media (min-width: 1585px) {
        margin-left: -40px;
        right: unset;
      }
    `};

  ${(props) =>
    props.isAsideButtonChecked === 'CLOSED' &&
    css`
      svg {
        transform: rotate(90deg);
      }
    `};
`;

export const ArrowIconAside = styled(SvgArrowAside)`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  stroke: currentColor;
  margin: 0 10px 0 0;
`;

export const ResetButton = styled.button<{ isMobile?: boolean }>`
  background: none;
  border: none;
  // padding: 0 8px;
  margin: 30px 0;
  text-transform: uppercase;
  font-size: var(--font-sm);
  line-height: 14px;
  font-weight: 700;
  transition: all 0.2s;
  color: var(--accent-primary);

  &:hover {
    color: var(--object-primary);
  }

  &:focus {
    outline: none;
  }

  ${(prop) =>
    prop.isMobile &&
    css`
      display: none;
      overflow: hidden;
    `}
`;

export const Sides = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  flex: 1;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background: var(--background);
  }
`;

export const Column = styled(TransferColumn)`
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  min-height: 100%;
  position: relative;
  max-height: 100vh;
  overflow: auto;
`;

export const Header = styled.header`
  padding: 24px 20px 10px 20px;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--base);
  justify-content: space-around;
  // z-index: 99;
  border: 1px solid var(--border);
  border-top: none;
  border-radius: 0 0 5px 5px;
  margin: 0 -1px;
`;

export const HeaderTitle = styled.div`
  text-align: center;
  width: 200px;
  font-weight: 600;
  font-size: var(--font-lg);
  line-height: 22px;
  color: var(--object-primary);
  justify-self: right;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;

  span {
    position: relative;
  }
`;

export const SearchInput = styled.input`
  padding-left: 25px;
  background: none;
  font-size: var(--font-xl);
  border-radius: 5px;
  line-height: 20px;
  width: 100%;
  outline: none;

  color: var(--object-primary);

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--object-primary);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #0a0a0a00;
  }
`;

export const IconSearch = styled(SvgSearch)`
  stroke: var(--object-secondary);
  width: 21px;
  height: 21px;
  position: absolute;
  top: 11px;
  margin-top: -10px;
  pointer-events: none;
`;

export const IconClear = styled(SvgClose)`
  height: 17px;
  width: 17px;
  cursor: pointer;
  stroke: var(--object-secondary);
  margin-left: 8px;
`;

export const SearchForm = styled.div`
  position: relative;
  left: 18px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const HeaderTitleWrap = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
`;

export const HeaderFilters = styled.div`
  display: flex;
`;

export const FilterWrap = styled.div`
  margin-top: 15px;
  display: flex;
`;

export const AccountFilter = styled.div<{
  active?: boolean;
}>`
  margin: 0 5px 5px 0;
  padding: 10px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: var(--font-xs);
  line-height: 10px;
  font-weight: 700;
  background-color: var(--border);
  color: var(--object-primary);
  user-select: none;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  height: 25px;

  &:hover {
    background-color: var(--placeholder);
  }

  ${(props) =>
    props.active &&
    css`
      background-color: var(--accent-primary);
      color: var(--dark-bg);

      &:hover {
        background-color: var(--accent-primary);
      }
    `}
`;

export const IconCancel = styled(SvgCancel)`
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0 10px 0 0;
  transition: all 0.3s;
  stroke: currentColor;

  @media (min-width: 1600px) {
    width: calc(10px * 1.25);
    height: calc(10px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(10px * 1.5);
    height: calc(10px * 1.5);
  }
`;

export const IconArrow = styled(SvgArrowRight)`
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0 0 0 10px;
  stroke: var(--dark-bg);
  transition: all 0.3s;

  @media (min-width: 1600px) {
    width: calc(10px * 1.25);
    height: calc(10px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(10px * 1.5);
    height: calc(10px * 1.5);
  }
`;

export const CurrencyBackDrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`;

export const ArrowFromTo = styled.div`
  padding: 10px;
  background: var(--base);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: var(--placeholder);
  position: absolute;
  top: 55px;
  left: 50%;
  margin-left: -20px;
  cursor: default;

  @media (min-width: 1600px) {
    width: calc(40px * 1.25);
    height: calc(40px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(40px * 1.5);
    height: calc(40px * 1.5);
  }
`;

export const ArrowIcon = styled(SvgArrow)`
  width: 20px;
  height: 20px;
  stroke: currentColor;
  transform: rotate(90deg);
`;

export const TransferBarLabel = styled.div<{
  ready?: boolean;
}>`
  text-align: center;
  background-color: var(--placeholder);
  margin: 0;
  width: 100%;
  font-size: var(--font-xxl);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  align-items: center;
  position: relative;
  z-index: 99;
  user-select: none;
  transition: all 0.4s;
  font-weight: ${({ ready }) => (ready ? 700 : 200)};

  @media (min-width: 1600px) {
    font-size: calc(${({ ready }) => (ready ? '18px' : '16px')} * 1.25);
    padding: calc(20px * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(${({ ready }) => (ready ? '18px' : '16px')} * 1.5);
    padding: calc(20px * 1.5);
  }

  ${(props) =>
    props.ready &&
    css`
      background-color: var(--accent-primary);
      color: var(--dark-bg);
      justify-content: space-between;
    `};
`;
export const TransferBarBody = styled(motion.div)`
  width: 100%;
  padding: 20px;
  position: relative;
  z-index: 2;

  @media (min-width: 1600px) {
    padding: calc(20px * 1.25);
  }

  @media (min-width: 2160px) {
    padding: calc(20px * 1.5);
  }
`;
export const TransferBar = styled(motion.div)<{
  ready?: boolean;
  isOpened?: boolean;
}>`
  display: flex;
  width: 480px;
  border-radius: 10px 10px 0 0;
  background: var(--base, #fff);
  z-index: 3;
  position: fixed;
  left: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  flex-wrap: wrap;
  padding: 0;
  transition: background-color 0.3s;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: 1600px) {
    width: calc(480px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(480px * 1.5);
  }
`;
export const ManagementWrap = styled.div``;
export const TransferHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;

  @media (min-width: 1600px) {
    margin: 0 0 calc(20px * 1.25) 0;
  }
  @media (min-width: 2160px) {
    margin: 0 0 calc(20px * 1.5) 0;
  }
`;
export const TransferTitle = styled.h3<{ success?: boolean }>`
  color: var(--object-primary, #00143c);
  font-size: var(--font-xl);
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (min-width: 1600px) {
    font-size: calc(var(--font-xl) * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-xl) * 1.5);
  }

  ${(props) =>
    props.success &&
    css`
      color: var(--success, #1bb560);
    `};
`;
export const IconBack = styled(SvgArrowRounded)`
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 10px 0;
  stroke: currentColor;
  transform: rotate(-90deg);

  @media (min-width: 1600px) {
    width: calc(20px * 1.25);
    height: calc(20px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(20px * 1.5);
    height: calc(20px * 1.5);
  }
`;
export const TransferBackToTransaction = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: var(--accent-primary, #0064ff);
  font-size: var(--font-xxs);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @media (min-width: 1600px) {
    font-size: calc(var(--font-xxs) * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-xxs) * 1.5);
  }
`;
export const TransferFooter = styled.footer`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
export const TransactionsLink = styled.a`
  cursor: pointer;
  font-size: var(--font-sm);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: 1600px) {
    font-size: calc(var(--font-sm) * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-sm) * 1.5);
  }
`;
export const OtpWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 290px;
`;
export const IconTransferArrow = styled(SvgArrowAside)`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  stroke: currentColor;
  margin: 0;
  transition: all 0.4s;

  @media (min-width: 1600px) {
    width: calc(20px * 1.25);
    height: calc(20px * 1.25);
    max-width: calc(20px * 1.25);
    min-width: calc(20px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(20px * 1.5);
    height: calc(20px * 1.5);
    max-width: calc(20px * 1.5);
    min-width: calc(20px * 1.5);
  }
`;
export const IconButton = styled.button<{ opened?: boolean }>`
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 0 10px;

  ${(props) =>
    props.opened &&
    css`
      ${IconTransferArrow} {
        transform: rotate(180deg);
      }
    `};
`;

const blink = (props: ThemeProps<any>) => keyframes`
    25%, 50%, 75% {
      color: var(--error);
    }
    0%, 100% {
      color: var(--base);
    }
`;

export const MinLimit = styled.div<{ isError: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isError &&
    css`
      animation: ${(props) => blink(props)} 1s linear;
    `}
`;
export const Fee = styled.div`
  display: flex;
  align-items: center;
`;

export const TransferLimit = styled.div`
  display: flex;
  font-size: var(--font-xxs);
  font-style: normal;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;

  span {
    font-family: Roboto Mono;
    color: var(--object-primary, #00143c);

    @media (min-width: 1600px) {
      font-size: calc(var(--font-xxs) * 1.25);
    }

    @media (min-width: 2160px) {
      font-size: calc(var(--font-xxs) * 1.5);
    }
  }
`;

export const Separator = styled.div`
  margin: 0 10px;
`;

export const FilterSmallBalancesLabel = styled.label<{ isDisabled?: boolean }>`
  user-select: none;
  margin-top: -15px;

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      & * {
        opacity: 0.5;
      }
    `}
`;

export const ChosenExchangeName = styled.div`
  font-size: var(--font-sm);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 14px;
`;

export const AccountWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ChosenExchangeIcon = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 30px;
  margin: 0 10px 0 0;

  @media (min-width: 1600px) {
    width: calc(30px * 1.25);
    height: calc(30px * 1.25);
    max-width: calc(30px * 1.25);
    min-width: calc(30px * 1.25);
    background-size: calc(30px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(30px * 1.5);
    height: calc(30px * 1.5);
    max-width: calc(30px * 1.5);
    min-width: calc(30px * 1.5);
    background-size: calc(30px * 1.5);
  }
`;

export const ChosenAccountName = styled.div`
  font-size: var(--font-md);
  font-weight: 700;
  flex: 1;

  @media (min-width: 1600px) {
    font-size: calc(var(--font-md) * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-md) * 1.5);
  }
`;

export const ChosenCurrencyAvailable = styled.div`
  font-size: var(--font-xxs);
  font-weight: 700;
  line-height: 10px;
  width: 100%;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;

  span {
    margin: 0 0 0 10px;
    color: var(--placeholder);
  }

  @media (min-width: 1600px) {
    font-size: calc(var(--font-xxs) * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-xxs) * 1.5);
  }
`;

export const GoToWalletsMessage = styled.div`
  display: flex;
  align-items: center;
  font-weight: 200;

  @media (min-width: 1600px) {
    font-size: calc(var(--font-xl) * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-xl) * 1.5);
  }
`;

export const GoToWalletsButton = styled.button<{ disabled?: boolean }>`
  padding: 5px 10px;
  border-radius: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--object-primary);
  background: var(--background);
  border: none;
  cursor: pointer;
  font-weight: 700;

  & > svg {
    stroke: var(--object-primary) !important;
  }

  span {
    line-height: 20px;
    font-size: var(--font-sm);
    text-transform: uppercase;
  }

  @media (min-width: 1600px) {
    padding: calc(5px * 1.25) calc(10px * 1.25);
  }

  @media (min-width: 2160px) {
    padding: calc(5px * 1.5) calc(10px * 1.5);
  }
`;

export const SendButton = styled.button<{ disabled?: boolean }>`
  padding: 10px 10px 10px 20px;
  border-radius: 40px;
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-bg);
  background: var(--accent-primary);
  border: none;
  cursor: pointer;
  user-select: none;

  @media (min-width: 1600px) {
    padding: calc(10px * 1.25) calc(10px * 1.25) calc(10px * 1.25)
      calc(20px * 1.25);
    }
  }

  @media (min-width: 2160px) {
    padding: calc(10px * 1.5) calc(10px * 1.5) calc(10px * 1.5) calc(20px * 1.5);
  }

  span {
    line-height: 20px;
    font-size: var(--font-sm);
    text-transform: uppercase;

    @media (min-width: 1600px) {
      font-size: calc(var(--font-sm) * 1.25);
    }

    @media (min-width: 2160px) {
      font-size: calc(var(--font-sm) * 1.5);
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      background: var(--placeholder);
      pointer-events: none;
    `};
`;

export const Send = styled.div`
  padding: 20px 0 0 0;
  width: 100%;
  flex: 1;
  color: var(--accent-primary);

  @media (min-width: 1600px) {
    padding: calc(20px * 1.25) 0 0 0;
  }
  @media (min-width: 2160px) {
    padding: calc(20px * 1.5) 0 0 0;
  }

  ${SendButton} {
    @media (min-width: 1600px) {
      font-size: calc(var(--font-md) * 1.25) !important;
    }
    @media (min-width: 2160px) {
      font-size: calc(var(--font-md) * 1.5) !important;
    }
  }
`;

export const IconGoToWallets = styled(SvgArrowRight)`
  width: 15px;
  height: 20px;
  margin: 0 0 0 5px;
  padding: 4px 0;
  stroke: var(--object-primary);
  stroke-width: 2px;

  @media (min-width: 1600px) {
    width: calc(15px * 1.25);
    height: calc(20px * 1.25);
    stroke-width: calc(2px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(15px * 1.5);
    height: calc(20px * 1.5);
    stroke-width: calc(2px * 1.5);
  }
`;

export const IconSend = styled(SvgArrowRight)`
  width: 15px;
  height: 20px;
  margin: 0 0 0 5px;
  padding: 4px 0;
  stroke-width: 2px;
  stroke: white;

  @media (min-width: 1600px) {
    width: calc(15px * 1.25);
    height: calc(20px * 1.25);
    stroke-width: calc(2px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(15px * 1.5);
    height: calc(20px * 1.5);
    stroke-width: calc(2px * 1.5);
  }
`;

export const AmountWrap = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const AmountInput = styled.input`
  appearance: none;
  font-size: var(--font-xxxxl);
  line-height: 32px;
  font-weight: 400;
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 32px;
  background: var(--base);
  color: var(--object-primary);
  font-family: var(--font-monospaced);
  transition: all 0.3s ease;
  outline: none;

  @media (min-width: 1600px) {
    font-size: calc(var(--font-xxxxl) * 1.25);
    line-height: calc(32px * 1.25);
    height: calc(32px * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-xxxxl) * 1.5);
    line-height: calc(32px * 1.5);
    height: calc(32px * 1.5);
  }

  &:focus {
    outline: none;
  }
`;
export const AmountInputWrap = styled.div<{ isError?: boolean }>`
  display: flex;
  border-radius: 5px;
  border: 1px solid var(--border, #d9dfe7);
  background: var(--base, #fff);
  padding: 4px 10px;
  flex: 1;
  align-items: center;

  @media (min-width: 1600px) {
    padding: calc(4px * 1.25) calc(10px * 1.25);
  }

  @media (min-width: 2160px) {
    padding: calc(4px * 1.5) calc(10px * 1.5);
  }

  ${(props) =>
    props.isError &&
    css`
      border-color: var(--error);

      ${AmountInput} {
        color: var(--error);
      }
    `};
`;

export const InsufficientFunds = styled.span<{ opened?: boolean }>`
  font-weight: 400;
  font-size: var(--font-xs);
  margin: 10px 0 0 0;
  width: 100%;
  color: var(--error);

  @media (min-width: 1600px) {
    font-size: calc(var(--font-xs) * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-xs) * 1.5);
  }

  ${(props) => props.opened && css``};
`;

export const AmountCurrency = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  color: var(--placeholder);
  position: absolute;
  top: 50%;
  right: 0;
  line-height: 18px;
  font-size: var(--font-md);
  margin-top: -9px;
  pointer-events: none;

  @media (min-width: 1600px) {
    font-size: calc(var(--font-md) * 1.25);
    line-height: calc(18px * 1.25);
    margin-top: calc(-9px * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(var(--font-md) * 1.5);
    line-height: calc(18px * 1.5);
    margin-top: calc(-9px * 1.5);
  }
`;

export const IconCurrency = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  background-position: 50%;
  background-repeat: no-repeat;
  margin: 0 10px 0 0;
  background-size: 20px;
  pointer-events: none;
  z-index: 2;

  @media (min-width: 1600px) {
    width: calc(20px * 1.25);
    height: calc(20px * 1.25);
    max-width: calc(20px * 1.25);
    min-width: calc(20px * 1.25);
    background-size: calc(20px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(20px * 1.5);
    height: calc(20px * 1.5);
    max-width: calc(20px * 1.5);
    min-width: calc(20px * 1.5);
    background-size: calc(20px * 1.5);
  }
`;

export const IconCurrencyMin = styled.div`
  width: 10px;
  height: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  margin: 0 20px 0 10px;
  background-size: 10px;
`;
export const IconCurrencyWalletMin = styled(IconCurrencyMin)`
  margin: 0 10px 0 0;
`;

export const ConfirmGap = styled.div`
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  padding: 40px 30px 40px 0;
`;

export const ConfirmSpace = styled.div`
  width: auto;
  flex: 1;
  position: relative;
`;

export const ConfirmTop = styled.div`
  width: 100%;
  background: var(--border);
  padding: 40px 0;
`;

export const ConfirmBottom = styled.div`
  padding: 40px 0;
`;

export const ConfirmTitle = styled.h3`
  width: 100%;
  font-size: var(--font-xxxxxxl);
  line-height: 43px;
  font-weight: 300;
  margin: 0 0 50px 0;
  color: var(--object-secondary);

  span {
    font-weight: 700;
    color: var(--object-primary);
  }
`;

export const ConfirmText = styled.div`
  font-size: var(--font-xxl);
  line-height: 22px;
  color: var(--object-secondary);

  span {
    font-weight: 700;
  }
`;

export const ConfirmAmountWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 30px 0;
`;

export const ConfirmCurrencyAmount = styled.div`
  font-size: var(--font-xxxxxxl);
  line-height: 40px;
  font-weight: 300;
  font-family: var(--font-monospaced);
`;

export const ConfirmCurrencyLogo = styled.div`
  width: 40px;
  height: 40px;
  max-width: 40px;
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0 15px 0 30px;
`;

export const ConfirmCurrency = styled.div`
  font-size: var(--font-xxxxxxl);
  line-height: 40px;
  font-weight: 300;
  color: var(--object-secondary);
`;

export const ConfirmExchangeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
`;

export const ConfirmExchangeIcon = styled.div`
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 40px;
  margin: 0 20px 0 0;
`;

export const ConfirmExchangeInfo = styled.div`
  font-size: var(--font-md);
  line-height: 17px;
  color: var(--object-secondary);
`;

export const ConfirmExchangeInfoTo = styled(ConfirmExchangeInfo)`
  margin: 0 0 5px 0;
`;

export const ConfirmExchangeName = styled.span`
  color: var(--object-primary);
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 5px 0 0;
`;

export const ConfirmAccountName = styled.span`
  color: var(--object-primary);
  margin: 0 5px 0 5px;
`;

export const ConfirmAccountBalance = styled.span`
  color: var(--object-primary);
  font-weight: 300;
  margin: 0 0 0 5px;
  color: var(--object-secondary);

  span {
    font-family: var(--font-monospaced);
    color: var(--object-primary);
    font-weight: 400;
  }
`;

export const ConfirmAdress = styled.div`
  font-size: var(--font-md);
  line-height: 17px;
  color: var(--object-secondary);
  font-family: var(--font-monospaced);
`;

export const ConfirmAddressError = styled.div`
  font-size: var(--font-md);
  line-height: 17px;
  color: var(--error);

  a {
    color: var(--accent-primary);
  }
`;

export const ConfirmArrowFromTo = styled(ArrowFromTo)`
  left: 0;
  top: auto;
  bottom: -60px;
  margin-left: 0;
`;

export const ConfirmTfa = styled.div`
  display: inline-flex;
  padding: 4px;
  align-items: center;
  border-radius: 100px;
  background: var(--warning);
`;

export const ConfirmTfaLabel = styled.label`
  font-size: var(--font-md);
  font-weight: bold;
  padding: 0 0 0 25px;
  margin: 0 22px 0 0;
  width: 100%;
  max-width: 105px;
  min-width: 105px;
  color: var(--object-primary);
`;

export const ConfirmTfaInput = styled.input`
  border: none;
  border-radius: 40px;
  appearance: none;
  font-size: var(--font-xl);
  line-height: 28px;
  font-weight: 700;
  padding: 3px 20px 3px 20px;
  width: 101px;
  max-width: 101px;
  min-width: 101px;
  height: 40px;
  color: var(--object-primary);
  font-family: var(--font-monospaced);
`;

export const ConfirmClose = styled.button`
  position: fixed;
  top: 40px;
  right: 40px;
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;
  z-index: 115;
`;

export const ConfirmCloseIcon = styled(SvgX)`
  width: 40px;
  height: 40px;
  stroke: var(--object-secondary);
`;

export const ConfirmButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 20px 0;
`;

export const ConfirmButton = styled.button`
  padding: 15px 25px;
  background: var(--object-secondary);
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin: 0 25px 0 0;
  color: var(--dark-bg);
  font-size: var(--font-sm);
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  min-width: 105px;
  text-align: center;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  &.main {
    background: var(--accent-primary);
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

export const СommentWrap = styled.section`
  position: relative;
`;

export const СommentLabel = styled.label`
  position: absolute;
  text-transform: uppercase;
  font-size: var(--font-md);
  font-weight: 700;
  margin: 0;
  color: var(--object-secondary);
  top: 20px;
  left: 20px;
`;

export const Сomment = styled.textarea`
  border: 1px solid var(--border);
  background: var(--highlight);
  color: var(--object-primary);
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  padding: 50px 20px 20px;
  resize: none;
`;

// new transfer window
export const Transfer = styled(motion.div)`
  position: fixed;
  width: 100%;
  // max-width: 480px;
  z-index: 999;

  // @media (min-width: 1600px) {
  //   max-width: calc(480px * 1.25);
  // }

  // @media (min-width: 2160px) {
  //   max-width: calc(480px * 1.5);
  // }
`;
export const TransferBody = styled(motion.div)`
  padding: 20px;
`;
export const TransferLabel = styled.div`
  padding: 20px;
  background: var(--accent-primary, #0064ff);
  width: 100%;
  display: flex;
  border-radius: 5px 5px 0px 0px;
`;
export const TransferLabelText = styled.h3`
  color: var(--dark-bg, #fff);
  font-size: var(--font-xxl);
  font-style: normal;
  font-weight: 700;
`;
