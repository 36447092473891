import { FC } from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

import { ReactComponent as SvgDownload } from 'shared/assets/icons/icon-download.svg';

interface ManagementAppProps {
  direct: string;
  playmarket: string;
}

const Wrap = styled.div`
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`;
const QrCodeVisual = styled(QRCode)`
  width: 78px;
  max-width: 78px;
  height: 78px;
  max-height: 78px;
  background: var(--dark-bg);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--border);
  margin: 0 0 0 20px;
`;
const StoreWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2px;
`;
const Store = styled.a``;
const StoreImg = styled.img`
  width: 135px;
  height: 40px;
`;
const Icon = styled(SvgDownload)`
  width: 20px;
  height: 20px;
  stroke: var(--accent-primary);
  margin: 0 10px 0 0;
  float: left;
`;
const StoreText = styled.div`
  font-size: var(--font-xxs);
  font-weight: 700;
  display: flex;
  width: 100%;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--object-secondary);

  a {
    color: var(--accent-primary);
    margin: 0 10px 0 0;
  }
`;

export const ManagementApp: FC<ManagementAppProps> = ({
  direct,
  playmarket,
}) => {
  return (
    <Wrap>
      <StoreWrap>
        <StoreText>
          <a href={direct}>
            <Icon /> Download
          </a>
          &nbsp;or
        </StoreText>
        <Store href={playmarket} target="_blank">
          <StoreImg src="/i/store/google-play.svg" alt="Google Play" />
        </Store>
      </StoreWrap>
      <QrCodeVisual value={playmarket} size={160} fgColor="#000000" />
    </Wrap>
  );
};
