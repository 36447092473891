import styled from 'styled-components/macro';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-direction-down.svg';

export const Container = styled.div`
  border-bottom: 2px solid var(--color-border-light);
  position: relative;
  margin-top: 10px;
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: var(--font-xl);
  color: var(--object-secondary);
`;

export const TextData = styled.span`
  font-size: var(--font-md);
`;
