import { StatusType } from 'shared/types';
import { TransactionStatusType } from 'services/Transactions/types';
import styled, { css } from 'styled-components/macro';

export const ConfirmGap = styled.div`
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  padding: 40px 30px 40px 0;
`;
export const TransactionStatusRow = styled.div`
  display: flex;
`;
export const TransactionStatusCol = styled.div`
  width: 100%;
  max-width: 270px;
  padding: 15px 0;
`;
export const TransactionStatusColFullWidth = styled.div`
  width: 100%;
  padding: 15px 0;
`;
export const FailedMessage = styled.div`
  font-size: var(--font-md);
  max-width: 420px;
  padding: 0 0 0 30px;
  line-height: 17px;
  border-left: 2px solid var(--error);
`;
export const FailedMessageDetail = styled.div`
  padding: 15px 0 0 0;
  font-size: var(--font-md);
  max-width: 420px;
  line-height: 17px;
  color: var(--object-secondary);
`;
export const StatusLabel = styled.div`
  font-size: var(--font-sm);
  width: 100%;
  font-weight: 700;
  color: var(--object-secondary);
  margin: 0 0 10px 0;
`;
export const StatusValue = styled.div`
  font-size: var(--font-md);
`;
export const Status = styled.span<{ status: TransactionStatusType }>`
  text-transform: capitalize;

  ${(prop) =>
    prop.status === StatusType.UNCONFIRMED &&
    css`
      color: var(--warning);
    `}

  ${(prop) =>
    prop.status === StatusType.PROCESSING &&
    css`
      color: var(--accent-primary);
    `}

  ${(prop) =>
    prop.status === StatusType.CANCELED &&
    css`
      color: var(--object-secondary);
    `}

    ${(prop) =>
    prop.status === StatusType.REJECTED &&
    css`
      color: var(--object-secondary);
    `}

  ${(prop) =>
    prop.status === StatusType.COMPLETED &&
    css`
      color: var(--success);
    `}
`;
export const StatusExchangeIcon = styled.div`
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin: 0 10px 0 0;
`;
export const StatusExchange = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px 0;
`;
export const StatusExchangeName = styled.div`
  color: var(--object-secondary);
`;
export const StatusAccountName = styled.div``;
export const FontMono = styled.span`
  font-family: var(--font-monospaced);
`;
export const StatusCurrency = styled.span`
  color: var(--object-secondary);
  margin: 0 0 0 5px;
`;

// todo: use shared confirm button
export const ConfirmButton = styled.button`
  padding: 15px 25px;
  background: var(--object-secondary);
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin: 0 25px 0 0;
  color: var(--dark-bg);
  font-size: var(--font-sm);
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  min-width: 105px;
  text-align: center;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  &.main {
    background: var(--accent-primary);
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;
export const OkButton = styled(ConfirmButton)`
  flex-direction: column;
`;
