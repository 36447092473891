// @ts-nocheck

import { FC } from 'react';
import { TransactionDetails } from '@gnosis.pm/safe-react-gateway-sdk';

import { formattedDate } from 'shared/helpers/date';
import * as T from '../../styled/transactions';
import {
  TransactionConfirmations,
  TransactionPrimaryDetails,
  TransactionSecondaryDetails,
  TxDetail,
  TransastionButtons,
} from './';
import { toJS } from 'mobx';
import styled from 'styled-components';

interface Props {
  item: any;
  details: TransactionDetails;
  isOpen: boolean;
  error: string;
}

const TransactionError = styled.div`
  margin-top: 12px;
  color: var(--error);
  font-size: var(--font-sm);
`;

export const TransactionDropdownInfo: FC<Props> = ({
  item,
  error,
  details,
  isOpen,
  isFirst,
}) => {
  if (!isOpen) return null;

  const { transaction } = item;
  const { txInfo, timestamp } = transaction;
  const { type, transactionHash } = txInfo;

  return (
    <T.DropdownInfo>
      <T.DropLeft>
        <TransactionPrimaryDetails tx={transaction} />
        {/* If transaction is safe creation, prevent details query */}
        {type === 'Creation' ? (
          <T.DropDataWrap>
            <TxDetail
              label={'Transaction hash'}
              value={transactionHash}
              canBeCopied
              isHash
            />
            <TxDetail
              label={'Created'}
              value={formattedDate(timestamp || 0, 'MMM DD YYYY, hh:mm A')}
            />
          </T.DropDataWrap>
        ) : (
          <TransactionSecondaryDetails tx={transaction} details={details} />
        )}
      </T.DropLeft>
      <T.DropRight>
        <TransactionConfirmations tx={transaction} details={details} />
        <TransastionButtons
          item={item}
          tx={transaction}
          details={details}
          isFirst={isFirst}
        />
        <TransactionError>{error}</TransactionError>
      </T.DropRight>
    </T.DropdownInfo>
  );
};
