import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';

const IconDropdown = styled(SvgArrow)`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  stroke: var(--object-secondary);
  display: inline-block;
  transition: all 0.2s;
`;
const IconWrap = styled.div<{ opened: boolean }>`
  margin: 0 0 0 10px;

  ${(prop) =>
    prop.opened &&
    css`
      ${IconDropdown} {
        transform: rotate(180deg);
      }
    `}
`;
const DropdownWrap = styled.div<{ title?: string }>`
  width: 100%;
  margin: 20px 0;
`;
const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const Title = styled.h4`
  font-size: var(--font-md);
  font-weight: 700;
  line-height: 20px;
`;
const Content = styled(motion.div)`
  padding: 20px 0;
`;

function Dropdown(props: any) {
  const title = props.title || 'Advanced parameters';
  const [dropdown, setDropDown] = useState(false);

  return (
    <DropdownWrap>
      <Header onClick={() => setDropDown(!dropdown)}>
        <Title>{title}</Title>
        <IconWrap opened={dropdown}>
          <IconDropdown />
        </IconWrap>
      </Header>
      <AnimatePresence initial={false}>
        {dropdown ? (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            <Content>{props.children}</Content>
          </motion.section>
        ) : null}
      </AnimatePresence>
    </DropdownWrap>
  );
}
export default Dropdown;
