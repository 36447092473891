import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthStoreInstance } from 'services';
import { useInput } from 'shared/hooks/useInput';
import Header from './Header';
import Button from '../Button';
import Input from '../Input';

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column' as const,
  width: '100%',
};

const contentStyle = {
  color: 'var(--object-primary)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  paddingBottom: '20px',
};

const formContainerStyle = {
  width: '420px',
  padding: '32px',
  paddingBottom: '32px',
  backgroundColor: 'var(--base)',
  borderRadius: '4px',
  border: '1px solid var(--border)',
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  marginTop: '80px',
};

const titleStyle = {
  fontFamily: 'Inter',
  WebkitFontSmoothing: 'auto',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 'var(--font-xxl)',
  marginBottom: '16px',
};

const messageStyle = {
  fontFamily: 'Inter',
  fontSize: 'var(--font-md)',
  color: 'var(--text)',
  marginBottom: '16px',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const buttonWrapperStyle = {
  marginTop: '24px',
  WebkitFontSmoothing: 'auto',
};

const buttonSpaceStyle = {
  width: '24px',
};

const backToLoginStyle = {
  marginTop: '16px',
  marginBottom: '-4px',
  display: 'block',
  textAlign: 'center' as const,
  fontWeight: 'bold',
  color: 'var(--accent-primary)',
  fontSize: 'var(--font-md)',
  cursor: 'pointer',
  width: 'fit-content',
};

const signupPromptStyle = {
  fontSize: 'var(--font-md)',
  marginTop: '16px',
};

const signupLinkStyle = {
  textAlign: 'center' as const,
  fontWeight: 'bold',
  color: 'var(--accent-primary)',
  cursor: 'pointer',
};

export default function ResetPassword() {
  const { resetPassword, setUserPassword, signOut } = AuthStoreInstance;
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const tokenFromPath = window.location.pathname.split('/')[2];
    setToken(
      String(
        tokenFromPath == 'undefined' || tokenFromPath == undefined
          ? undefined
          : tokenFromPath,
      ),
    );
  }, []);
  const password = useInput('');
  const confirmPassword = useInput('');
  const email = useInput('');
  const [mailSent, setMailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const onSetPassword = async () => {
    try {
      if (password.value !== confirmPassword.value) {
        password.setError('Passwords do not match');
        confirmPassword.setError('Passwords do not match');
        return;
      }
      await setUserPassword(String(token), password.value);
      signOut();
      setPasswordChanged(true);
    } catch (error) {
      // @ts-ignore
      password.setError(error?.response?.data?.error_message);
    }
  };

  const onClick = async () => {
    try {
      setIsLoading(true);
      await resetPassword(email.value);
      setMailSent(true);
    } catch (error) {
      // @ts-ignore
      email.setError(error?.response?.data?.error_message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={containerStyle}>
      <Header />
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
        >
          <div style={contentStyle}>
            <div style={formContainerStyle}>
              {passwordChanged ? (
                <div>
                  <div style={{ ...titleStyle, marginBottom: '24px' }}>
                    Password successfully changed
                  </div>
                  <div style={messageStyle}>
                    Your password has been successfully updated.
                  </div>
                  <div style={{ ...messageStyle, marginBottom: '12px' }}>
                    You can now log in using your new password.
                  </div>

                  <div style={{ ...buttonContainerStyle, marginTop: '40px', marginBottom: '-16px' }}>
                    <a href="/login">
                      <Button
                        loading={false}
                        before={<div style={buttonSpaceStyle}></div>}
                        after={<div style={buttonSpaceStyle}></div>}
                        type={isLoading ? 'disabled' : 'primary'}
                        text="Login"
                      />
                    </a>
                  </div>
                </div>
              ) : mailSent ? (
                <div>
                  <div style={{ ...titleStyle, marginBottom: '24px' }}>
                    Check your email
                  </div>
                  <div style={messageStyle}>
                    If an account exists for {email.value}, you will receive an
                    email with instructions on how to reset your password.
                  </div>
                  <div style={messageStyle}>
                    If you don't see it in your inbox, please check your spam
                    folder.
                  </div>
                  <div style={buttonContainerStyle}>
                    <a href="/login" style={backToLoginStyle}>
                      Back to login
                    </a>
                  </div>
                </div>
              ) : token !== undefined &&
                token !== 'undefined' &&
                token?.length > 0 ? (
                <div>
                  <div style={{ ...titleStyle, marginBottom: '24px' }}>
                    Set a new password
                  </div>
                  <div>
                    <Input
                      removable={true}
                      placeholder="New password"
                      type="password"
                      error={password.error}
                      value={password.value}
                      onChange={password.onChange}
                    />
                  </div>
                  <div>
                    <Input
                      removable={true}
                      placeholder="Confirm password"
                      type="password"
                      error={confirmPassword.error}
                      value={confirmPassword.value}
                      onChange={confirmPassword.onChange}
                    />
                  </div>
                  <div style={buttonWrapperStyle}>
                    <Button
                      loading={false}
                      before={<div style={buttonSpaceStyle}></div>}
                      after={<div style={buttonSpaceStyle}></div>}
                      onClick={onSetPassword}
                      type={isLoading ? 'disabled' : 'primary'}
                      text="Submit"
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div style={titleStyle}>
                    Enter your email to reset password
                  </div>
                  <div style={messageStyle}>
                    We'll send you a link and instructions to reset your
                    password.
                  </div>
                  <div>
                    <Input
                      removable={true}
                      placeholder="Email"
                      type="text"
                      error={email.error}
                      value={email.value}
                      onChange={email.onChange}
                    />
                  </div>
                  <div style={buttonWrapperStyle}>
                    <Button
                      loading={false}
                      before={<div style={buttonSpaceStyle}></div>}
                      after={<div style={buttonSpaceStyle}></div>}
                      onClick={onClick}
                      type={isLoading ? 'disabled' : 'primary'}
                      text="Reset password"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
