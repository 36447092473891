import styled, { css } from 'styled-components/macro';

export const TabWrap = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // padding: 0 0 15px 20px;
`;
export const TabItem = styled.div<{ active: boolean }>`
  padding: 8px 0;
  margin: 0 30px 0 0;
  font-weight: 700;
  font-size: var(--font-xxl);
  line-height: 22px;
  color: var(--object-secondary);
  cursor: pointer;
  position: relative;
  user-select: none;
  text-align: center;

  &:last-child {
    margin: 0;
  }

  @media (max-width: 765px) {
    max-width: 145px;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--object-primary);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background: var(--accent-primary);
      }
    `};
`;
export const TabItemFw = styled.div<{ align?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.align === 'right' &&
    css`
      align-items: flex-end;
    `};
  ${(props) =>
    props.align === 'left' &&
    css`
      align-items: flex-start;
    `};
`;
export const TabButton = styled.button<{ color?: string }>`
  display: flex;
  line-height: 10px;
  font-size: var(--font-xxxs);
  padding: 5px 10px;
  border-radius: 20px;
  text-transform: uppercase;
  color: var(--base);
  background-color: var(--accent-primary);
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: var(--object-primary);
  }

  ${(props) =>
    props.color === 'remove' &&
    css`
      background-color: var(--error);

      &:hover {
        opacity: 0.8;
      }
    `};
`;
