import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';

import { ReactComponent as SvgX } from 'shared/assets/icons/icon-cancel.svg';
import { ReactComponent as SvgUser } from 'shared/assets/icons/icon-user.svg';
import { ReactComponent as SvgClock } from 'shared/assets/icons/icon-clock.svg';
import { ReactComponent as SvgEth } from 'shared/assets/icons/icon-ethereum.svg';

export const Wrap = styled(motion.div)`
  min-height: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const IconX = styled(SvgX)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  stroke: currentColor;
  margin: 0 0 0 10px;
`;
export const ContentWrap = styled.div`
  height: 100%;
  padding: 0 0 0 0;
`;
export const NoAvailable = styled.div`
  font-size: var(--font-xxl);
  color: var(--object-secondary);
  background: var(--highlight);
  padding: 60px 20px;
  text-align: center;
  border-radius: 5px;
`;
export const Button = styled.button<{ disabled?: boolean }>`
  font-size: var(--font-sm);
  line-height: 20px;
  text-transform: uppercase;
  background-color: var(--accent-primary);
  color: var(--dark-bg);
  padding: 10px;
  font-weight: 700;
  width: auto;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
  display: flex;

  & + & {
    margin: 0 0 0 10px;
  }
  ${(prop) =>
    prop.disabled &&
    css`
      background: var(--placeholder);
      color: var(--dark-bg);
      pointer-events: none;
    `}
`;

export const SignButton = styled(Button)`
  min-width: 136px;
  white-space: nowrap;
  justify-content: center;
`;
export const CancelButton = styled(Button)`
  background: none;
  color: var(--accent-primary);

  &:hover {
    background: none;
    color: var(--object-primary);
  }
`;
export const AdvancedList = styled.section`
  padding: 20px;
  background: var(--highlight);
  display: flex;
  margin: 20px 0;
`;
export const AdvancedItem = styled.div`
  width: 33%;
  min-width: 33%;
  max-width: 33%;
  display: flex;
  padding: 0 20px 0 0;
`;
export const AdvancedItemWrap = styled.div`
  padding: 0 0 0 20px;
  max-width: 240px;
`;
export const AdvancedTitle = styled.h4`
  font-size: var(--font-md);
  font-weight: 700;
  margin: 0 0 20px 0;
`;
export const AdvancedText = styled.div`
  font-size: var(--font-sm);
`;
export const AdvancedIcon = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 40px;
  color: var(--base);
  background-color: var(--object-primary);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconUser = styled(SvgUser)`
  width: 20px;
  height: 20px;
  stroke: currentColor;
`;
export const IconClock = styled(SvgClock)`
  width: 20px;
  height: 20px;
  stroke: currentColor;
`;
export const IconEth = styled(SvgEth)`
  width: 20px;
  height: 20px;
  stroke: currentColor;
`;
